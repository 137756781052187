import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AuthContext } from "../authorization/AuthProvider";
import Button from "../components/reusable/button";
import Card from "../components/reusable/card";

function Admin() {
  const { t, i18n } = useTranslation();
  let auth = React.useContext(AuthContext);

  const ButtonRow = styled.div`
    margin-top: 10px;
    line-height: 3rem;
  `;
  
  return (
    <div>
      <Card>
        {/* TODO: Schlössli Symbol */}
        <h4>{t('admin.verwaltung')}</h4>
        <h2>{t('admin.adminHinweis')}</h2>
        <ButtonRow>
          <Button>{t('admin.imkerPruefen')}</Button>
          <Button>{t('admin.deklarationenPruefen')}</Button>
          <Button>{t('admin.imkerVerwalten')}</Button>
          <Button>{t('admin.fragenkatalogVerwalten')}</Button>
          <Button>{t('admin.termine')}</Button>
          <Button>{t('admin.reporting')}</Button>
          <Button>{t('admin.exporte')}</Button>          
        </ButtonRow>
      </Card>
    </div>
  );
}

export default Admin;
