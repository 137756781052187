import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ImkerStatus, KickoffterminAuswahlDto } from "../../generated/api/generated-api";
import { GetKickoffTermine } from "../../services/backend/Kickofftermine";
import { Beutensystem, Hoehe, Kanton, MyFormData } from "./MyFormData";
import { Spinner } from "react-bootstrap";
import { spawn } from "child_process";

const MySpinner = styled(Spinner)`
  position: absolute;
  margin-top: -25px;
  margin-left: 15px;
  color: gray;
`;

interface RegistrierungImkerAngabenProps extends FormikProps<MyFormData> {
  kickoffTerminAuswahl: KickoffterminAuswahlDto[];
  teilnahmeErneuern: boolean;
  imkerStatus: ImkerStatus;
}

function RegistrierungImkerAngaben(props: RegistrierungImkerAngabenProps) {
  console.log(props);

  const { t, i18n } = useTranslation();

  const { values, handleChange, handleBlur, errors, kickoffTerminAuswahl, setFieldValue } = props;

  function GetAuswahlText(text: string | null | undefined): import("react").ReactNode | Iterable<import("react").ReactNode> {
    if (text?.includes("{BEREITS_TEILGENOMMEN}")) return t("anmeldung.imker_kickoffBereitsBesucht");
    return text;
  }

  // //https://www.carlrippon.com/drop-down-data-binding-with-react-hooks/
  // const [kickoffTerminAuswahl, setKickoffTerminAuswahl] = useState<KickoffterminAuswahlDto[]>([{ id: 0, text: "" }]);
  // const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   setIsLoading(true);
  //   GetKickoffTermine().then((data) => {
  //     setKickoffTerminAuswahl(data!); //TODO: error händling
  //     setIsLoading(false);
  //   });
  // }, []);

  return (
    <>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.imker_seitWann")}</Form.Label>
          <Form.Control name="imker_seitWann" type="text" value={values.imker_seitWann} onChange={handleChange} isInvalid={!!errors.imker_seitWann}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.imker_seitWann}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.imker_anzahlVoelker")}</Form.Label>
          <Form.Control name="imker_anzahlVoelker" type="text" value={values.imker_anzahlVoelker} onChange={handleChange} isInvalid={!!errors.imker_anzahlVoelker}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.imker_anzahlVoelker}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.imker_beutensystem")}</Form.Label>
          <Form.Check type="checkbox" label={t("anmeldung.imker_beutensystemSchweizerkasten")} name="beutenSystemAuswahl" onChange={handleChange("imker_beutensystem_schweizerkasten")} checked={values.imker_beutensystem_schweizerkasten}></Form.Check>
          <Form.Check type="checkbox" label={t("anmeldung.imker_beutensystemMagazin")} name="beutenSystemAuswahl" onChange={handleChange("imker_beutensystem_magazin")} checked={values.imker_beutensystem_magazin}></Form.Check>
          {/* <Form.Control.Feedback type="invalid">{errors.beutenSystemAuswahl}</Form.Control.Feedback> */}
          {errors.beutenSystemAuswahl && <span style={{ color: "red" }}>*</span>}
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.imker_varroa")}</Form.Label>
          <Form.Check type="radio" label={t("anmeldung.ja")} name="imker_varroaunterlage" checked={props.values.imker_varroaunterlage === true} onChange={() => props.setFieldValue("imker_varroaunterlage", true)} isInvalid={!!errors.imker_varroaunterlage}></Form.Check>
          <Form.Check type="radio" label={t("anmeldung.nein")} name="imker_varroaunterlage" checked={props.values.imker_varroaunterlage === false} onChange={() => props.setFieldValue("imker_varroaunterlage", false)} isInvalid={!!errors.imker_varroaunterlage}></Form.Check>
          <Form.Control.Feedback type="invalid">{errors.imker_varroaunterlage}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.imker_herausnehmbareWaben")}</Form.Label>
          <Form.Check type="radio" label={t("anmeldung.ja")} name="imker_herausnehmbareWaben" checked={props.values.imker_herausnehmbareWaben === true} onChange={() => props.setFieldValue("imker_herausnehmbareWaben", true)} isInvalid={!!errors.imker_herausnehmbareWaben}></Form.Check>
          <Form.Check type="radio" label={t("anmeldung.nein")} name="imker_herausnehmbareWaben" checked={props.values.imker_herausnehmbareWaben === false} onChange={() => props.setFieldValue("imker_herausnehmbareWaben", false)} isInvalid={!!errors.imker_herausnehmbareWaben}></Form.Check>
          <Form.Control.Feedback type="invalid">{errors.imker_herausnehmbareWaben}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.imker_hoehe")}</Form.Label>
          <Form.Select name="imker_hoehe" value={values.imker_hoehe} onChange={handleChange} isInvalid={!!errors.imker_hoehe}>
            <option value=""></option>
            {Object.keys(Hoehe).map((key) => (
              <option key={key} value={Hoehe[key as keyof typeof Hoehe]}>
                {t(Hoehe[key as keyof typeof Hoehe])}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.imker_verein")}</Form.Label>
          <Form.Check type="radio" label={t("anmeldung.ja")} name="imker_mitgliedVerein" checked={props.values.imker_mitgliedVerein === true} onChange={() => props.setFieldValue("imker_mitgliedVerein", true)} isInvalid={!!errors.imker_mitgliedVerein}></Form.Check>
          <Form.Check type="radio" label={t("anmeldung.nein")} name="imker_mitgliedVerein" checked={props.values.imker_mitgliedVerein === false} onChange={() => props.setFieldValue("imker_mitgliedVerein", false)} isInvalid={!!errors.imker_mitgliedVerein}></Form.Check>
          <Form.Control.Feedback type="invalid">{errors.imker_herausnehmbareWaben}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.imker_sektion")}</Form.Label>
          <Form.Control name="imker_sektion" type="text" value={values.imker_sektion} onChange={handleChange} isInvalid={!!errors.imker_sektion}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.imker_sektion}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.imker_kanton")}</Form.Label>
          <Form.Select name="imker_kanton" value={values.imker_kanton} onChange={handleChange} isInvalid={!!errors.imker_kanton}>
            <option value=""></option>
            {Object.keys(Kanton).map((key) => (
              <option key={key} value={Kanton[key as keyof typeof Kanton]}>
                {t(Kanton[key as keyof typeof Kanton])}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      </Row>
      {(!props.teilnahmeErneuern || (props.teilnahmeErneuern && props.imkerStatus === 50)) && (
        <Row className="mb-3">
          <Form.Group as={Col} md="4">
            <Form.Label>{t("anmeldung.imker_kickoffTermin")}</Form.Label>
            <Form.Select name="imker_kickoffTermin" value={values.imker_kickoffTermin} onChange={handleChange} isInvalid={!!errors.imker_kickoffTermin}>
              <option value=""></option>
              {kickoffTerminAuswahl.map((auswahl) => (
                <option key={auswahl.id} value={auswahl.id}>
                  {GetAuswahlText(auswahl.text)}
                </option>
              ))}
            </Form.Select>
            {/* TODO: Styled-Component (het grad nid funktioniert :-( )) */}
            {/* {isLoading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" style={{position: "absolute", marginTop: "-25px", marginLeft: "15px", color: "gray"}} />} */}
          </Form.Group>
        </Row>
      )}
    </>
  );
}

export default RegistrierungImkerAngaben;
