import styled from "styled-components";

export const AmpelContainer = styled.div`
    display: inline-flex;
    flex-direction: row;
    border-radius: 4px;
    background-color: #ae8f00;
    padding: 7px;
    gap: 7px;
    margin-bottom: 10px;
  `;
