import styled from "styled-components";

const Button = styled.button`
  cursor: pointer;
  display: inline;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  margin-right: 0.5rem;
  font-size: 1rem;
  font-weight: 400 !important;
  line-height: 1.5;
  color: white;
  background-color: #ae8f00;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition-duration: 0.15s, 0.15s, 0.15s, 0.15s;
  transition-timing-function: ease-in-out, ease-in-out, ease-in-out, ease-in-out;
  transition-delay: 0s, 0s, 0s, 0s;
  transition-property: color, background-color, border-color, box-shadow;

  &:hover {
    background-color: #343a40;
  }

  :disabled {
    background-color: #dacb8b;
    color: #f0f0f0;
    cursor: default;
  }
`;

export default Button;
