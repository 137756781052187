import { Link } from "react-router-dom";
import styled from "styled-components";

const ButtonLink = styled(Link)`
    text-decoration: none;
    color: inherit;

    &:hover {
        color: inherit;
    }
`;

export default ButtonLink;