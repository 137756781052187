import { getGetKickofftermine, KickoffterminAuswahlDto } from "../../generated/api/generated-api";

// const TIMEOUT_MS = 15000; // 5 seconds timeout

// const abortController = new AbortController();
// const timeoutId = setTimeout(() => {
//   abortController.abort();
// }, TIMEOUT_MS);

//TODO: Rückgabeewert?
export async function GetKickoffTermine(): Promise<KickoffterminAuswahlDto[] | void> {
   
    try {
        const data = await getGetKickofftermine();
        if (data.status === 200) {
            return data.data;
        } else {
            return Promise.reject();
        }
    } catch (error) {
        return Promise.reject();
    } 
    // finally {
    //     clearTimeout(timeoutId);
    // }
}