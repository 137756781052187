import { useTranslation } from "react-i18next";
import AgbDe from "./AgbDe";
import AgbFr from "./AgbFr";
import AgbIt from "./AgbIt";

function Agb()  {
    const {t, i18n} = useTranslation();

    if (i18n.language === 'de') return <AgbDe />
    if (i18n.language === 'fr') return <AgbFr />
    if (i18n.language === 'it') return <AgbIt />
    return <></>
}

export default Agb;