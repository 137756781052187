import { getGetImkerByEmail, getGetImkerById, getGetNewestImkerStatus, ImkerDto, ImkerStatusMitDatumDto, postTeilnahmeKuendigenDurchImker } from "../../generated/api/generated-api";

export async function GetImkerByEmail(email: string): Promise<ImkerDto | void> {
  try {
    const data = await getGetImkerByEmail({ email });
    console.log(data);
    if (data.status === 500) {
      console.log("Error");
      return Promise.reject();
    }
    if (data.status === 200) {
      return data.data;
    }
  } catch (error) {
    console.log(error);
    return Promise.reject();
  }
}

export async function GetImkerById(id: number, token: string): Promise<ImkerDto | void> {
  try {
    const data = await getGetImkerById(
      { id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(data);
    if (data.status === 500) {
      console.log("Error");
      return Promise.reject();
    }
    if (data.status === 200) {
      return data.data;
    }
  } catch (error) {
    console.log(error);
    return Promise.reject();
  }
}

export async function TeilnahmeKuendigen(imkerId: number, token: string) {
  try {
    const data = await postTeilnahmeKuendigenDurchImker(
      { imkerId: imkerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return Promise.reject();
    }
  } catch (error) {
    console.log(error);
    return Promise.reject();
  }
}

// Neuster Status (kann auch in zukunft liege )
export async function GetNewestImkerStatus(imkerId: number): Promise<ImkerStatusMitDatumDto | void> {
  try {
    const data = await getGetNewestImkerStatus({ imkerId });

    if (data.status === 200) {
      return data.data;
    } else {
      return Promise.reject();
    }
  } catch (error) {
    console.log(error);
    return Promise.reject();
  }
}
