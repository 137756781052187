import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { TerminDto, TerminTyp } from "../../generated/api/generated-api";

const TermineItemContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  row-gap: 2px;
  column-gap: 4px;
`;


function GetLink(link: string): string {
  if (!link.startsWith("http://") && !link.startsWith("https://")) {
    return `https://${link}`;
  }
  return link;
}

function Termine(props: { termine: TerminDto[] }) {
  const { t, i18n } = useTranslation();

  function GetTerminTyp(terminTyp: TerminTyp): string {
    if (terminTyp === 1) return t("meineGesundheitsprogramme.terminTyp_Kickoff");
    if (terminTyp === 2) return t("meineGesundheitsprogramme.terminTyp_ERFA");
    return "";
  }

  return (
    <>
      <h2>{t("meineGesundheitsprogramme.termineTitel")}</h2>
      <TermineItemContainer>
        {props.termine.map((item) => (
            <>
            <span>{new Date(item.datum!).toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit"})}</span>
            <span>{GetTerminTyp(item.terminTyp!)}</span>
            <span>{item.time!.substring(0, 5)}</span>
            <span>{item.ort}</span>
            <a href={GetLink(item.link!)} target="_blank">{t("meineGesundheitsprogramme.link")}</a>
            </>
        ))}
      </TermineItemContainer>
    </>
  );
}

export default Termine;
