import React, { useEffect } from "react";
import { postLogin } from "../generated/api/generated-api";
import { useCookies } from "react-cookie";
import { getDomain } from "../services/DomainService";

const GesundheitsprogrammCookieName = "Gesundheitsprogrammcookie";

interface AuthContextType {
  user: User | undefined;
  userLoading: boolean;
  signin: (user: string, password: string, callback: (response: SignInResponse) => void) => void;
  signout: (callback: VoidFunction) => void;
}

export type SignInResponse = {
  isAdmin: boolean;
  isUnauthorized: boolean;
  isBetriebskonzeptUser: boolean;
  userId: number;
};

export const AuthContext = React.createContext<AuthContextType>(null!);

interface User {
  id: number;
  isAdmin: boolean;
  token: string;
}

function AuthProvider({ children }: { children: React.ReactNode }) {
  console.log("AuthProvider Init");
  var initialUser: User | undefined;

  const [user, setUser] = React.useState(initialUser);
  const [cookies, setCookie, removeCookie] = useCookies([GesundheitsprogrammCookieName]);
  const [userLoading, setUserLoading] = React.useState(true);

  function setUserCookie(user: User, isBetriebskonzeptUser: boolean, token: string) {
    if (getDomain().includes("azurewebsites.net")) {
      // Fallback if cookie cannot be created
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("isAdmin", user.isAdmin.toString());
      sessionStorage.setItem("isBetriebskonzeptUser", isBetriebskonzeptUser.toString());
      sessionStorage.setItem("id", user.id.toString());
    } else {
      var value = new URLSearchParams();
      value.set("token", token);
      value.set("isAdmin", user.isAdmin.toString());
      value.set("isBetriebskonzeptUser", isBetriebskonzeptUser.toString());
      value.set("id", user.id.toString());
      setCookie(GesundheitsprogrammCookieName, value.toString(), { domain: getDomain(), encode: (v) => v });
    }
  }

  let signin = (email: string, password: string, callback: (response: SignInResponse) => void) => {
    return postLogin({ email: email, password: password })
      .then((data) => {
        //TODO: evtl. HTTP-only cookies?
        //TODO: Unauthorized handling

        if (data.status === 500) {
          throw Error("Server Error");
        }
        if (data.status === 401) {
          callback({ isAdmin: false, isBetriebskonzeptUser: false, isUnauthorized: true, userId: 0 });
        }
        if (data.status === 200) {
          const authenticatedUser: User = {
            id: data.data.id!,
            isAdmin: data.data.isAdmin!,
            token: data.data.token!
          };
          setUser(authenticatedUser);
          setUserCookie(authenticatedUser, data.data.isBetriebskonzeptUser!, data.data.token!);
          callback({ isAdmin: data.data.isAdmin || false, isBetriebskonzeptUser: data.data.isBetriebskonzeptUser || false, isUnauthorized: false, userId: authenticatedUser.id });
        }
      })
      .catch((error) => {
        //TODO: Error handling, Anzeige UI
        console.log(error);
        callback({ isAdmin: false, isBetriebskonzeptUser: false, isUnauthorized: true, userId: 0 });
      });
  };

  let signout = (callback: VoidFunction) => {
    setUser(undefined);
    removeCookie(GesundheitsprogrammCookieName, { path: "/", domain: getDomain()});
    removeSession();
    callback();
  };

  function removeSession() {
    sessionStorage.removeItem("id");
    sessionStorage.removeItem("isAdmin");
    sessionStorage.removeItem("isBetriebskonzeptUser");
    sessionStorage.removeItem("token");
  }

  //TODO: testen ob das so funktioniert
  useEffect(() => {
    if (getDomain().includes("azurewebsites.net")) {
      // Fallback if cookie cannot be created
      if (sessionStorage.getItem("id") != null) {
        const authenticatedUser: User = {
          id: Number(sessionStorage.getItem("id")),
          isAdmin: sessionStorage.getItem("isAdmin")!.toLowerCase() === "true",
          token: sessionStorage.getItem("token")!
        };
        setUser(authenticatedUser);
      }
    } else {
      // check if cookie is set from Betriebskonzept
      if (cookies.Gesundheitsprogrammcookie) {
        var params = new URLSearchParams(cookies.Gesundheitsprogrammcookie);
        const authenticatedUser: User = {
          id: Number(params.get("id")!),
          isAdmin: params.get("isAdmin")!.toLowerCase() === "true",
          token: params.get("token")!
        };
        setUser(authenticatedUser);
      }
    }
    console.log("COOKIE: SetUserLoading false");
    setUserLoading(false);
  }, []);

  let value = { user, userLoading, signin, signout };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthProvider;
