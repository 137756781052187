import styled from "styled-components";
import logoBienenKleinHeader from "../../assets/images/logo_bienen_klein_header.png";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import "./dropdown.css";
import { AuthContext } from "../../authorization/AuthProvider";
import React, { useState } from "react";
import { ChevronDown, List } from "react-bootstrap-icons";
import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";
import { isDevUrl, isLocalDevUrl, isProdUrl, isTestUrl } from "../../services/DomainService";

const StyledTitleMenu = styled.div`
  grid-area: titlemenu;
  display: grid;
  grid-template-columns: minmax(0, 140px) auto auto;
  align-items: center;
  /* padding: 0 10px 0 10px; */
`;

const MobileMenuIcon = styled(List)`
  display: grid;
  justify-self: end;
  margin-right: 10px;
  cursor: pointer;
  @media (min-width: 1001px) {
    display: none;
  }
`;

const Logo = styled.div`
  margin: 10px 0 10px 10px;
`;

const Title = styled.a`
  font-size: 1.25rem;
  text-decoration: none;
  color: inherit;
`;

function TitleMenu() {
  const { t, i18n } = useTranslation();
  let auth = React.useContext(AuthContext);
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  function toggleMobileMenu() {
    setShowMobileMenu(!showMobileMenu);
  }

  let navigate = useNavigate();
  let logout = () => {
    auth.signout(() => {
      if (isLocalDevUrl()) {
        window.location.href = `https://localhost:44397/Home/hiddenlogout`;
      } else if (isTestUrl()) {
        window.location.href = `https://betriebskonzept-apiservice-test.azurewebsites.net/Home/hiddenlogout`;
      } else if (isProdUrl()) {
        window.location.href = `https://betriebskonzept.apiservice.ch/Home/hiddenlogout`;
      } else {
        navigate("/login");
      }    
    });
  };

  return (
    <StyledTitleMenu>
      <Logo>
        <img src={logoBienenKleinHeader} alt="Logo Bienen"></img>
      </Logo>
      {/* TODO: Root URL BIENEN.ch */}
      <Title href={t("menu.titelUrl").toString()}>{t("menu.titel")}</Title>
      <DesktopMenu logout={logout}></DesktopMenu>
      <MobileMenuIcon size={50} onClick={toggleMobileMenu}></MobileMenuIcon>
      <MobileMenu logout={logout} show={showMobileMenu} onClickLink={toggleMobileMenu}></MobileMenu>
    </StyledTitleMenu>
  );
}

export default TitleMenu;
