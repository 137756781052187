import styled from "styled-components";

const Card = styled.div`
    //TODO: Color variables
    background-color: #F6F6F6;
    position: relative;
    border-radius: 16px;
    border: 0.125px solid rgba(0,0,0,0.175);
    box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
    width: 100%;
    display: block;
    padding: 16px;
`;



export default Card;
