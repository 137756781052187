import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";

function AgbIt() {
  const { t, i18n } = useTranslation();

  const Titel = styled.h2`
    margin-bottom: 2rem;
  `;

  return (
    <div>
      <Titel>CG che regolano la partecipazione al programma salute</Titel>
      <h2>Stipula del contratto</h2>
      <p>
      Iscrivendosi online all’indirizzo{" "}
        <a className="inlineAnchor" href="/">
          programmasalute.apiservice.ch
        </a>{" "}
        i partecipanti stipulano un contratto con apiservice Sagl. (fornitore del programma) e dichiarano di accettare le presenti Condizioni generali (CG). I partecipanti confermano di aver letto le condizioni di partecipazione pubblicate e di approvarle.
      </p>
      <h2>Obblighi dei partecipanti</h2>
      <p>
      I partecipanti si impegnano esplicitamente a:
        <ul>
          <li>
          praticare l’apicoltura in conformità del concetto aziendale e a rispettare le{" "}
            <a className="inlineAnchor" href="/condizioniquadro">
              condizioni quadro
            </a>{" "}
            nonché le raccomandazioni del Servizio sanitario apistico (SSA);
          </li>
          <li>fornire informazioni sulle proprie attività apistiche in maniera conforme al vero nell’autodichiarazione annuale disponibile online;</li>
          <li>pagare le spese di partecipazione entro un termine di 30 giorni.</li>
        </ul>
      </p>
      <p>apiservice verifica che l’autodichiarazione rispetti il concetto aziendale del SSA e le condizioni quadro del programma salute. Se tali condizioni sono rispettate, i partecipanti ricevono la targhetta di riconoscimento «Apicoltura responsabile riconosciuta».</p>
      <p>Le persone che non soddisfano tali condizioni non ricevono il riconoscimento. Non esiste alcun diritto al rimborso delle spese di partecipazione.</p>
      <p>Le prestazioni non utilizzate non possono essere né recuperate né trasferite a terzi e non danno diritto ad alcun rimborso.</p>
      <h2>Obblighi del fornitore del programma</h2>
      <p>apiservice si impegna a fornire l’integralità delle prestazioni di servizi indicate nel sito Internet. Qualora non lo facesse, i partecipanti hanno diritto a un rimborso parziale delle spese di partecipazione su base proporzionale.</p>
      <p>Qualora i partecipanti ritengano che apiservice non abbia assolto i propri obblighi di cui alla presente relazione contrattuale, sono tenuti a comunicarlo per iscritto o via e-mail. In mancanza di tale comunicazione, non esiste alcun diritto a un rimborso parziale delle spese di partecipazione.</p>
      <h2>Protezione dei dati</h2>
      <p>
      apiservice si impegna a trattare i dati personali dei partecipanti a norma di legge e a rispettare le disposizioni sul diritto alla protezione dei dati. I dati sono registrati in una banca dati sicura in Svizzera e non sono trasmessi a terzi. apiservice si riserva il diritto di procedere a valutazioni e a comunicazioni pubbliche dei dati in forma anonima.
      </p>
      <p>Eventuali registrazioni video o audio degli scambi di esperienze online possono essere effettuate soltanto previo accordo esplicito di apiservice e dei partecipanti.</p>
      <h2>Spese di partecipazione</h2>
      <p>
      Le spese di partecipazione attualmente in vigore sono pubblicate all’indirizzo{" "}
        <a className="inlineAnchor" href="/">
          programmasalute.apiservice.ch
        </a>
        . I costi indicati sono da intendersi IVA inclusa. Verrà preparata la fattura per le spese di partecipazione, salvo comunicazione di altre opzioni di pagamento online.
      </p>
      <p>Non esiste alcun diritto al rimborso su base proporzionale delle spese di partecipazione, salvo in caso di decesso o di incapacità a partecipare per motivi di salute attestata da un medico.</p>
      <h2>Ritardo di pagamento</h2>
      <p>Ogni ritardo di pagamento comporta l’esclusione dal programma.</p>
      <h2>Durata del contratto e risoluzione / esclusione</h2>
      <p>
      Il contratto è stipulato con durata indeterminata. Può essere risolto in qualsiasi momento e senza preavviso per la fine di un anno civile (31 dicembre). La risoluzione deve essere effettuata nell’area di login del programma salute o per iscritto (via posta o via e-mail all’indirizzo{" "}
        <a className="inlineAnchor" href="mailto:info@apiservice.ch">
          info@apiservice.ch
        </a>
        ).
      </p>
      <p>La risoluzione della partecipazione in corso d’anno è esclusa.</p>
      <p>apiservice ha il diritto di sospendere il programma salute in qualsiasi momento. Inoltre, apiservice può escludere qualsivoglia partecipante dal programma salute senza indicarne le ragioni. In tal caso, le spese di partecipazione saranno rimborsate su base proporzionale.</p>
      <h2>Clausola di non responsabilità e assicurazione</h2>
      <p>Per tutte le manifestazioni organizzate da apiservice, apiservice esclude ogni responsabilità per eventuali danni. I partecipanti sono tenuti ad avere una copertura assicurativa adeguata.</p>
      <h2>Modifiche alle spese di partecipazione e alle CG</h2>
      <p>Eventuali modifiche al prezzo o al programma salute sono generalmente apportate all’inizio dell’anno. Le CG restano valide per un anno.</p>
      <p>apiservice informa preventivamente i partecipanti in maniera appropriata delle corrispondenti modifiche. Se nessuna risoluzione è effettuata dai partecipanti a seguito di tale informazione, le nuove condizioni si applicano automaticamente.</p>
      <h2>Clausola di salvaguardia</h2>
      <p>Qualora una disposizione delle presenti CG sia invalida o incompleta, o la sua esecuzione si riveli impossibile, la validità delle altre disposizioni resta inalterata. In tal caso, la disposizione invalida deve essere sostituita con una disposizione valida che consenta di conseguire l’obiettivo previsto dal contratto in maniera giuridicamente ammissibile e che si avvicini il più possibile all’intenzione originale. Lo stesso vale in caso di lacuna.</p>
      <h2>Diritto applicabile e foro competente</h2>
      <p>I termini delle presenti CG sono soggetti esclusivamente al diritto svizzero. Il foro competente per ogni controversia relativa alla presente convenzione è – salvo disposizione cogente contraria del diritto di procedura civile – Appenzello.</p>
      <p>Fatto in: marzo 2023</p>
      <p><strong>apiservice Sagl</strong><br/>Jakob Signer-Strasse 4<br/>9050 Appenzello</p>
    </div>
  );
}

export default AgbIt;
