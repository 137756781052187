import { FormikProps } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Alert from "../../components/reusable/alert";
import { MyFormData } from "./MyFormData";

interface RegistrierungImkerAngabenProps extends FormikProps<MyFormData> {
  loginFailed: boolean;
}

function RegistrierungBetriebskonzeptPasswort(props: RegistrierungImkerAngabenProps) {
  const { t, i18n } = useTranslation();

  const { values, handleChange, errors } = props;

  return (
    <>
      <p>{t("anmeldung.betriebskonzeptPasswort")}</p>
      <Alert show={props.loginFailed}>{t("login.failed")}</Alert>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>
            {t("anmeldung.passwort")} für {values.email}
          </Form.Label>
          <Form.Control name="password" type="password" value={values.password} onChange={handleChange} isInvalid={!!errors.password}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
        </Form.Group>
      </Row>
    </>
  );
}
export default RegistrierungBetriebskonzeptPasswort;
