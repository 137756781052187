export type LanguageMenu = {
    SelectedLanguageText: string,
    SelectedLanguage: string,
    MenuItem1Text: string,
    MenuItem1: string,
    MenuItem2Text: string,
    MenuItem2: string
  }
  
export function GetLanguageMenu(selectedLanguage: string): LanguageMenu {
    let languageMenu = {} as LanguageMenu;
  
    languageMenu.SelectedLanguage = selectedLanguage;
    languageMenu.SelectedLanguageText = selectedLanguage.toUpperCase();
  
    if (selectedLanguage === "de") {
      languageMenu.MenuItem1 = "fr";
      languageMenu.MenuItem1Text = "FR"
      languageMenu.MenuItem2 = "it";
      languageMenu.MenuItem2Text = "IT"
    }
    if (selectedLanguage === "fr") {
      languageMenu.MenuItem1 = "de";
      languageMenu.MenuItem1Text = "DE"
      languageMenu.MenuItem2 = "it";
      languageMenu.MenuItem2Text = "IT"
    }
    if (selectedLanguage === "it") {
      languageMenu.MenuItem1 = "de";
      languageMenu.MenuItem1Text = "DE"
      languageMenu.MenuItem2 = "fr";
      languageMenu.MenuItem2Text = "FR"
    }
  
    return languageMenu;
  }