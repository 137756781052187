import { CircleFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { AmpelContainer } from "./Ampel";


function StatusGekuendetBGD() {
  const { t, i18n } = useTranslation();

  return (
    <>
      <AmpelContainer>
        <div>
          <CircleFill size={25} color="#ffffffab"></CircleFill>
        </div>
        <div>
          <CircleFill size={25} color="#ffffffab"></CircleFill>
        </div>
        <div>
          <CircleFill size={25} color="#ff3c3cab"></CircleFill>
        </div>
      </AmpelContainer>
      <p>{t("meineGesundheitsprogramme.teilnehmerStatusGekuendetDurchBGD")}</p>
    </>
  );
}

export default StatusGekuendetBGD;
