import { useTranslation } from "react-i18next";
import styled from "styled-components";
import footerImage from "../../assets/images/footer.png";

const StyledFooter = styled.div`
  grid-area: footer;
  background-color: #ffe600;
  background-image: url(${footerImage});
  background-position: left top;
  background-repeat: repeat-x;
  background-size: contain;

  display: grid;
  grid-template-columns: auto minmax(0, 900px) auto;
  grid-template-areas: ". footerContent .";
`;

const FooterContent = styled.div`
  grid-area: footerContent;
  margin-top: 2em;
  padding: 0 10px 0 10px;
`;

const Middot = styled.span`
  margin-left: 0.5em;
  margin-right: 0.5em;
`;

const Link = styled.a`
  text-decoration: none;
  color: inherit;
`;

function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <StyledFooter>
      <FooterContent>
        {t("footer.bienenCH")}
        <Middot>&middot;</Middot>
        {t("footer.titelText")}
        <Middot>&middot;</Middot>
        <Link href={t("footer.kontaktLink").toString()}>{t("footer.kontakt")}</Link>
        <Middot>&middot;</Middot>
        <Link href={t("footer.impressumLink").toString()}>{t("footer.impressum")}</Link>
        <Middot>&middot;</Middot>
        <Link href={t("footer.bestimmungenLink").toString()}>{t("footer.bestimmungen")}</Link>
      </FooterContent>
    </StyledFooter>
  );
}

export default Footer;
