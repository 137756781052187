import { KickoffterminAuswahlDto } from "../../generated/api/generated-api";

export enum Sprache {
  de = 'anmeldung.spracheDeutsch',
  fr = 'anmeldung.spracheFranz',
  it = 'anmeldung.spracheItalienisch'
}

export enum Anrede {
  m = 'anmeldung.anredeHerr',
  f = 'anmeldung.anredeFrau'
}

export enum Land {
  ch = 'anmeldung.landSchweiz',
  li = 'anmeldung.landLiechtenstein',
  xx = 'anmeldung.landAndere'
}

export enum Beutensystem {
  schweizerkasten = "anmeldung.imker_beutensystemSchweizerkasten",
  magazin = "anmeldung.imker_beutensystemMagazin"
}

export enum Hoehe {
  bis600 = "anmeldung.imker_hoeheBis600",
  _601bis900 = "anmeldung.imker_hoehe601bis900",
  _901bis1200 = "anmeldung.imker_hoehe901bis1200",
  ueber1200 = "anmeldung.imker_hoeheUeber1200"
}

export enum Kanton {
  AG	= "anmeldung.imker_kanton_AG",
  AI	= "anmeldung.imker_kanton_AI",
  AR	= "anmeldung.imker_kanton_AR",
  BE	= "anmeldung.imker_kanton_BE",
  BL	= "anmeldung.imker_kanton_BL",
  BS	= "anmeldung.imker_kanton_BS",
  FR	= "anmeldung.imker_kanton_FR",
  GE	= "anmeldung.imker_kanton_GE",
  GL	= "anmeldung.imker_kanton_GL",
  GR	= "anmeldung.imker_kanton_GR",
  JU	= "anmeldung.imker_kanton_JU",
  LU	= "anmeldung.imker_kanton_LU",
  NE	= "anmeldung.imker_kanton_NE",
  NW	= "anmeldung.imker_kanton_NW",
  OW	= "anmeldung.imker_kanton_OW",
  SG	= "anmeldung.imker_kanton_SG",
  SH	= "anmeldung.imker_kanton_SH",
  SO	= "anmeldung.imker_kanton_SO",
  SZ	= "anmeldung.imker_kanton_SZ",
  TG	= "anmeldung.imker_kanton_TG",
  TI	= "anmeldung.imker_kanton_TI",
  UR	= "anmeldung.imker_kanton_UR",
  VD	= "anmeldung.imker_kanton_VD",
  VS	= "anmeldung.imker_kanton_VS",
  ZG	= "anmeldung.imker_kanton_ZG",
  ZH	= "anmeldung.imker_kanton_ZH",
  Andere = "anmeldung.imker_kanton_andere",
  Liechtenstein = "anmeldung.imker_kanton_liechtenstein"
}

export type MyFormData = {
    email: string;
    sprache: Sprache | undefined;
    anrede: Anrede | undefined;
    vorname: string;
    nachname: string;
    strasseNr: string;
    plz: number | undefined;
    ort: string;
    land: Land | undefined;
    tel: string;
    telValidation: string;
    emailValidation: string;
    password: string;
    passwordValidation: string;
    imker_seitWann: string;
    imker_anzahlVoelker: number | undefined;
    //imker_beutensystem: Array<Beutensystem>;
    beutenSystemAuswahl: string;
    imker_beutensystem_schweizerkasten: boolean | undefined;
    imker_beutensystem_magazin: boolean | undefined;
    imker_varroaunterlage: boolean | undefined;
    imker_herausnehmbareWaben: boolean | undefined;
    imker_hoehe: Hoehe | undefined;
    imker_mitgliedVerein: boolean | undefined;
    imker_sektion: string;
    imker_kanton: Kanton | undefined;
    imker_kickoffTermin: number | undefined,
    bestaetigung_agb: boolean,
    bestaetigung_kostenpflichtig: boolean
  }
  