import { ChevronDown } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { GetLanguageMenu } from "./LanguageMenu";
import { GetBKMeineKonzepteLink, GetBKRahmenbedinungenLink, GetBKUebersichtLink } from "../../services/BetriebskonzeptLinks";
import { useContext } from "react";
import { AuthContext } from "../../authorization/AuthProvider";

const StyledMenu = styled.div`
  justify-self: right;
  display: inline;

  @media (max-width: 1000px) {
    display: none;
  }
`;

interface Props {
  logout: () => void;
}

function DesktopMenu(props: Props) {
  const { t, i18n } = useTranslation();
  let auth = useContext(AuthContext);
  const user = auth.user;
  let languageMenu = GetLanguageMenu(i18n.language);;

  return (
    <StyledMenu>
      {/* TODO: In Styled-Components schreiben (tricky) */}
      <div className="dropdown">
        <a href={GetBKUebersichtLink().toString()} className="dropdownLink">
          {t("menu.betriebskonzept")}
          <span className="chevron">
            <ChevronDown />
          </span>
        </a>
        <div className="dropdown-content">
          <div className="dropdown-item">
            <a href={GetBKUebersichtLink().toString()} className="dropdownLink">
              {t("menu.uebersicht")}
            </a>
          </div>
          <div className="dropdown-item">
            <a href={GetBKRahmenbedinungenLink().toString()} className="dropdownLink">
              {t("menu.rahmenbedingungen")}
            </a>
          </div>
          <div className="dropdown-item">
            <a href={GetBKMeineKonzepteLink().toString()} className="dropdownLink">
              {t("menu.meineKonzepte")}
            </a>
          </div>
        </div>
      </div>
      <div className="dropdown">
        <a href="#" className="dropdownLink dropdownLink_Active">
          {t("menu.gesundheitsprogramm")}
          <span className="chevron">
            <ChevronDown />
          </span>
        </a>
        <div className="dropdown-content">
          <div className="dropdown-item">
            <Link to="/" className="dropdownLink">
              {t("menu.uebersicht")}
            </Link>
          </div>
          <div className="dropdown-item">
            <Link to="/rahmenbedingungen" className="dropdownLink">
              {t("menu.rahmenbedingungen")}
            </Link>
          </div>
          <div className="dropdown-item">
            <Link to="/neuanmeldung" className="dropdownLink">
              {t("menu.neuanmeldung")}
            </Link>
          </div>
          <div className="dropdown-item">
            <Link to="/meinprogramm" className="dropdownLink">
              {t("menu.selbstdeklarationen")}
            </Link>
          </div>
        </div>
      </div>
      <div className="dropdown">
        <a href="#" className="dropdownLink">
          {languageMenu.SelectedLanguageText}
          <span className="chevron">
            <ChevronDown />
          </span>
        </a>
        <div className="dropdown-content">
          <div className="dropdown-item">
            <a href="#" onClick={() => i18n.changeLanguage(languageMenu.MenuItem1)} className="dropdownLink">
              {languageMenu.MenuItem1Text}
            </a>
          </div>
          <div className="dropdown-item">
            <a href="#" onClick={() => i18n.changeLanguage(languageMenu.MenuItem2)} className="dropdownLink">
              {languageMenu.MenuItem2Text}
            </a>
          </div>
        </div>
      </div>
      <div className="dropdown">
        {user && (
          <a href="#" onClick={props.logout} className="dropdownLink">
            Logout
          </a>
        )}
        {!user && (
          <Link to="/login" className="dropdownLink">
            Login
          </Link>
        )}
      </div>
    </StyledMenu>
  );
}

export default DesktopMenu;
