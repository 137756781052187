import styled from "styled-components";
import { Col, Form, FormGroup, Row } from "react-bootstrap";
import { FormikProps } from "formik";
import { Beutensystem, MyFormData } from "./MyFormData";
import { Trans, useTranslation } from "react-i18next";
import { KickoffterminAuswahlDto } from "../../generated/api/generated-api";
import Alert from "../../components/reusable/alert";

interface RegistrierungImkerAngabenProps extends FormikProps<MyFormData> {
  kickoffTerminAuswahl: KickoffterminAuswahlDto[];
  isSubmitFailed: boolean;
}

function RegistrierungBestaetigung(props: RegistrierungImkerAngabenProps) {
  const { t, i18n } = useTranslation();

  const { values, handleChange, errors, isValid, kickoffTerminAuswahl } = props;

  const ZusammenfassungContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    gap: 1em 1em;
    grid-auto-flow: row;
    grid-template-areas:
      ". ."
      ". .";
    margin-bottom: 2rem;
  `;

  const Stammdaten = styled.div`
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    grid-template-rows: 4em 4em 4em 4em 4em 4em 4em 4em 4em 4em;
    gap: 1em 1em;
    grid-auto-flow: row;
    grid-template-areas:
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". .";
  `;

  const ImkerAngaben = styled.div`
    display: grid;
    grid-template-columns: 1.2fr 0.8fr;
    grid-template-rows: 4em 4em 4em 4em 4em 4em 4em 4em 4em 4em 4em;
    gap: 1em 1em;
    grid-auto-flow: row;
    grid-template-areas:
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". ."
      ". .";
  `;

  const Anmeldung = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1.5em 1.5em;
    gap: 1em 1em;
    grid-auto-flow: row;
    grid-template-areas:
      "."
      ".";
  `;

  function getJaNein(value: boolean | undefined): string {
    return value ? t("anmeldung.ja") : t("anmeldung.nein");
  }

  function getBeutensystem(schweizerkasten: boolean, magazin: boolean): string {
    let beutensystemarray: Beutensystem[] = [];
    if (schweizerkasten) beutensystemarray.push(Beutensystem.schweizerkasten);
    if (magazin) beutensystemarray.push(Beutensystem.magazin);
    return beutensystemarray.map(t).join(", ");
  }

  const AGBLabel = function()  {
    return <Trans i18nKey="anmeldung.imker_agbAkzeptieren"><a className="inlineAnchor" target="__blank" href="/agb"></a></Trans>
  }

  return (
    <>
      <ZusammenfassungContainer>
        <Stammdaten>
          <div>{t("anmeldung.sprache")}</div>
          <div>{t(props.values.sprache!)}</div>
          <div>{t("anmeldung.anrede")}</div>
          <div>{t(props.values.anrede!)}</div>
          <div>{t("anmeldung.vorname")}</div>
          <div>{props.values.vorname}</div>
          <div>{t("anmeldung.nachname")}</div>
          <div>{props.values.nachname}</div>
          <div>{t("anmeldung.strasseNr")}</div>
          <div>{props.values.strasseNr}</div>
          <div>{t("anmeldung.plz")}</div>
          <div>{props.values.plz}</div>
          <div>{t("anmeldung.ort")}</div>
          <div>{props.values.ort}</div>
          <div>{t("anmeldung.land")}</div>
          <div>{t(props.values.land!)}</div>
          <div>{t("anmeldung.telefon")}</div>
          <div>{props.values.tel}</div>
          <div>{t("anmeldung.email")}</div>
          <div>{props.values.email}</div>
        </Stammdaten>
        <ImkerAngaben>
          <div>{t("anmeldung.imker_seitWann")}</div>
          <div>{props.values.imker_seitWann}</div>
          <div>{t("anmeldung.imker_anzahlVoelker")}</div>
          <div>{props.values.imker_anzahlVoelker}</div>
          <div>{t("anmeldung.imker_beutensystem")}</div>
          <div>{getBeutensystem(props.values.imker_beutensystem_schweizerkasten!, props.values.imker_beutensystem_magazin!)}</div>
          <div>{t("anmeldung.imker_varroa")}</div>
          <div>{getJaNein(props.values.imker_varroaunterlage)}</div>
          <div>{t("anmeldung.imker_herausnehmbareWaben")}</div>
          <div>{getJaNein(props.values.imker_herausnehmbareWaben)}</div>
          <div>{t("anmeldung.imker_hoehe")}</div>
          <div>{t(props.values.imker_hoehe!)}</div>
          <div>{t("anmeldung.imker_verein")}</div>
          <div>{getJaNein(props.values.imker_mitgliedVerein)}</div>
          <div>{t("anmeldung.imker_sektion")}</div>
          <div>{props.values.imker_sektion}</div>
          <div>{t("anmeldung.imker_kanton")}</div>
          <div>{t(props.values.imker_kanton!)}</div>
          <div>{t("anmeldung.imker_kickoffTermin")}</div>
          <div>{getKickoffText()}</div>
        </ImkerAngaben>
        <Anmeldung>
          {/* TODO: AGB's verlinken! */}
          {/* TODO: Betrag nach Stichdatum anpassen! */}
          <FormGroup>
          <Form.Check type="checkbox" name="bestaetigung_agb" label={<AGBLabel />} checked={values.bestaetigung_agb} onChange={handleChange("bestaetigung_agb")} isInvalid={!!errors.bestaetigung_agb}></Form.Check>
          <Form.Control.Feedback type="invalid">{errors.bestaetigung_agb}</Form.Control.Feedback>
          <Form.Check type="checkbox" name="bestaetigung_kostenpflichtig" label={t("anmeldung.imker_kostenpflichtigAnmelden")}  checked={values.bestaetigung_kostenpflichtig} onChange={handleChange("bestaetigung_kostenpflichtig")} isInvalid={!!errors.bestaetigung_kostenpflichtig}></Form.Check>
          <Form.Control.Feedback type="invalid">{errors.bestaetigung_kostenpflichtig}</Form.Control.Feedback>
          </FormGroup>
          {isValid}
        </Anmeldung>
        <Alert show={props.isSubmitFailed}>{t("anmeldung.anmeldungFehler")}</Alert>
      </ZusammenfassungContainer>
    </>
  );

  function getKickoffText() {
    if (props.values.imker_kickoffTermin === undefined) return t("anmeldung.imker_kickoffBereitsBesucht");

    var kickoff = kickoffTerminAuswahl.find(t => t.id === Number(props.values.imker_kickoffTermin))?.text!;
    if (kickoff.includes("{BEREITS_TEILGENOMMEN}"))  return t("anmeldung.imker_kickoffBereitsBesucht");
    
    return kickoff;
  }
}

export default RegistrierungBestaetigung;
