import Card from "../../components/reusable/card";
import { CircleFill } from "react-bootstrap-icons";
import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { GetImkerByEmail, GetImkerById, GetNewestImkerStatus, TeilnahmeKuendigen } from "../../services/backend/Imker";
import StatusInValidierung from "./StatusInValidierung";
import { ImkerDto, ImkerSelbstdeklarationListItemDto, ImkerStatus, ImkerStatusMitDatumDto, SelbstdeklarationDto, TerminDto } from "../../generated/api/generated-api";
import StatusAbgelehnt from "./StatusAbgelehnt";
import StatusAufgenommen, { StatusAufgenommenProps, inZukunftGekuendigt } from "./StatusAngenommen";
import { GetFutureTermine } from "../../services/backend/Termine";
import Termine from "./Termine";
import SelbstdeklarationListe from "./SelbdeklarationListe";
import { GetImkerSelbstdeklarationListe } from "../../services/backend/Selbstdeklarationen";
import StatusGekuendetBGD from "./StatusGekuendetBGD";
import StatusGekuendetImker from "./StatusGekuendetImker";
import Alert from "../../components/reusable/alert";
import { AuthContext } from "../../authorization/AuthProvider";

const MySpinner = styled(Spinner)`
  margin-top: 25px;
  margin-left: 50%;
  color: gray;
`;

function GetStatusComponent(props: { status: number; inZukunftGekuendigt: inZukunftGekuendigt; teilnahmeGueltigBis: string; teilnahmeKuendigen: () => void }): JSX.Element {
  if (props.status === 10) {
    return <StatusInValidierung></StatusInValidierung>;
  }
  if (props.status === 20) {
    return <StatusAufgenommen inZukunftGekuendigt={props.inZukunftGekuendigt} teilnahmeGueltigBis={props.teilnahmeGueltigBis} teilnahmeKuendigen={props.teilnahmeKuendigen}></StatusAufgenommen>;
  }
  if (props.status === 30) {
    return <StatusGekuendetBGD />;
  }
  if (props.status === 40) {
    return <StatusGekuendetImker />;
  }
  if (props.status === 50) {
    return <StatusAbgelehnt></StatusAbgelehnt>;
  }
  return <></>;
}

const MeinProgrammContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  row-gap: 10px;

  @media (max-width: 1000px) {
    grid-template-columns: 1fr;
  }
`;

function MeinProgramm() {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const [currentImkerStatus, setCurrentImkerStatus] = useState(0);
  const [newestImkerStatus, setNewestImkerStatus] = useState(0);
  const [teilnahmeGueltigBis, setTeilnahmeGueltigBis] = useState("");
  const [termine, setTermine] = useState<TerminDto[]>([]);
  const [selbstDeklarationen, setSelbstdeklarationen] = useState<ImkerSelbstdeklarationListItemDto[]>([]);
  const [showSelbstdeklarationen, setShowSelbstdeklarationen] = useState(false);
  const [loading, setLoading] = useState(false);

  let auth = useContext(AuthContext);
  const user = auth.user;
  const userLoading = auth.userLoading;

  useEffect(() => {
    console.log("COOKIE: MeinProgramm useEffect, userLoading: " + userLoading);
    if (userLoading) return;

    if (user === undefined) {
      console.log("COOKIE: navigate Login");
      navigate("/login");
      return;
    }

    setLoading(true);
    Promise.all([GetImkerById(user.id, auth.user?.token!), GetFutureTermine(user.id, auth.user?.token!), GetImkerSelbstdeklarationListe(user.id, auth.user?.token!), GetNewestImkerStatus(user.id)])
      .then((results) => {
        setCurrentImkerStatus(results[0]!.imkerStatus!);
        setTermine(results[1]!);
        setSelbstdeklarationen(results[2]!);
        setShowSelbstdeklarationen(getShowSelbstdeklaration(results[0]!, results[1]!, results[2]!));
        setNewestImkerStatus(results[3]!.imkerStatus!);
        setTeilnahmeGueltigBis(GetTeilnahmeGueltigBis(results[3]!));
      })
      .finally(() => {
        setLoading(false);
      });
    // GetImker(JSON.parse(userFromStorage!).email).then((imker) => {
    //   setImkerStatus(imker!.imkerStatus!);
    // });
    // GetFutureTermine().then((termine) => {
    //   setTermine(termine!);
    // });
  }, [userLoading]);

  function getShowSelbstdeklaration(imkerDto: ImkerDto, termine: TerminDto[], selbstdeklarationen: ImkerSelbstdeklarationListItemDto[]): boolean {
    if (selbstdeklarationen.length === 0) return false;

    if (imkerDto.imkerStatus === 10) return false;

    var kickoffTermin: TerminDto | undefined = termine.find((t) => t.terminTyp === 1);
    if (kickoffTermin === undefined) {
      // Kein Kickoff-Termin in der Zukunft = schon erledigt
      return true;
    }

    return false;
  }

  function GetTeilnahmeGueltigBis(imkerStatus: ImkerStatusMitDatumDto): string {
    if (imkerStatus.imkerStatus === 30 || imkerStatus.imkerStatus === 40) {
      var gueltigAb = new Date(imkerStatus.gueltigAb!);
      var gueltigBis = new Date(gueltigAb);
      gueltigBis.setDate(gueltigAb.getDate() - 1);
      return gueltigBis.toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" });
    }

    return "";
  }

  function getInZukunftGekuendigt(): inZukunftGekuendigt {
    if (newestImkerStatus === 30) return inZukunftGekuendigt.GekuendigtDurchBGD;
    if (newestImkerStatus === 40) return inZukunftGekuendigt.GekuendigtDurchImker;
    return inZukunftGekuendigt.NichtGekuendigt;
  }

  function onTeilnahmeKuendigen() {
    TeilnahmeKuendigen(user?.id!, user!.token).then(() => {
      window.location.reload();
    });
  }

  return (
    <>
      {loading && <MySpinner></MySpinner>}
      {!loading && (
        <>
          <h2>{t("meineGesundheitsprogramme.titel")}</h2>
          <p>{t("meineGesundheitsprogramme.einleitungText")}</p>
          <MeinProgrammContainer>
            {currentImkerStatus !== 0 ? (
              <Card>
                <h2>{t("meineGesundheitsprogramme.teilnehmerStatus")}</h2>
                <GetStatusComponent status={currentImkerStatus} inZukunftGekuendigt={getInZukunftGekuendigt()} teilnahmeGueltigBis={teilnahmeGueltigBis} teilnahmeKuendigen={onTeilnahmeKuendigen} />
              </Card>
            ) : (
              <p style={{ color: "red" }}>
                <Trans i18nKey="meineGesundheitsprogramme.hinweisNeuanmeldung">
                  <a className="inlineAnchor" href={t("meineGesundheitsprogramme.hinweisNeuanmeldungLink").toString()}></a>
                </Trans>
              </p>
            )}
            {currentImkerStatus === 20 && (
              <>
                <Card>
                  <Termine termine={termine}></Termine>
                </Card>
              </>
            )}
            {showSelbstdeklarationen && <SelbstdeklarationListe liste={selbstDeklarationen} imkerStatus={currentImkerStatus}></SelbstdeklarationListe>}
          </MeinProgrammContainer>
        </>
      )}
    </>
  );
}

export default MeinProgramm;
