import { useTranslation } from "react-i18next";
import styled from "styled-components";
import backgroundImage from "../../assets/images/background_waben.png";
import bannerImage from "../../assets/images/banner_image.png";
import bannerImageFr from "../../assets/images/banner_image_fr.png";
import bannerImageIt from "../../assets/images/banner_image_it.png";

const BannerContainer = styled.div`
  grid-area: banner;
  background-color: #ffe600;
  background-image: linear-gradient(to right, #ffe600, #ffe600 75%);
`;

const InnerBanner = styled.div`
  background-image: url(${backgroundImage});
  background-repeat: repeat-x;
  background-position: left top;
  background-size: contain;
  align-items: center;
  height: 100%;
`;

const BannerImage = styled.img`
  height: 90%;
  margin-left: auto;
  margin-right: auto;
`;

function Banner() {

  const { t, i18n } = useTranslation();
  const bannerImageSrc = i18n.language === "fr" ? bannerImageFr : i18n.language === "it" ? bannerImageIt : bannerImage;

  return (
    <BannerContainer>
      <InnerBanner>
        <BannerImage src={bannerImageSrc} alt="Banner Image"></BannerImage>
      </InnerBanner>
    </BannerContainer>
  );
}

export default Banner;
