import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";

function AgbFr() {
  const { t, i18n } = useTranslation();

  const Titel = styled.h2`
    margin-bottom: 2rem;
  `;

  return (
    <div>
      <Titel>CG régissant la participation au programme de santé</Titel>
      <h2>Conclusion du contrat</h2>
      <p>
      En s’inscrivant en ligne sur{" "}
        <a className="inlineAnchor" href="/">
          programmedesante.apiservice.ch
        </a>{" "}
        , les participants concluent un contrat avec apiservice s.à.r.l. (fournisseur du programme) et déclarent accepter les présentes conditions générales (CG). Les participants confirment avoir lu les conditions de participation publiées et les approuvent.
      </p>
      <h2>Obligations des participants</h2>
      <p>
      Les participants s’engagent explicitement à :
        <ul>
          <li>
          à pratiquer l’apiculture conformément au concept d’exploitation et à respecter les{" "}
            <a className="inlineAnchor" href="/conditionscadre">
              conditions cadre
            </a>{" "}
            ainsi que les recommandations du Service sanitaire apicole (SSA) ;
          </li>
          <li>à renseigner leurs activités apicoles de manière conforme à la vérité sur l’autodéclaration annuelle disponible en ligne ;</li>
          <li>à payer les frais de participation dans un délai de 30 jours.</li>
        </ul>
      </p>
      <p>apiservice vérifie que l’autodéclaration respecte le concept d’exploitation du SSA et les conditions-cadres du programme de santé. Si lesdites conditions sont respectées, les participants reçoivent la plaquette « Apiculture responsable reconnue ».</p>
      <p>Les personnes qui ne remplissent pas ces conditions ne seront pas reconnues. Il n’existe aucun droit au remboursement des frais de cours.</p>
      <p>Les prestations non utilisées ne peuvent être ni rattrapées ni transférées à des tiers et ne donnent droit à aucun remboursement.</p>
      <h2>Obligations du fournisseur de programme</h2>
      <p>apiservice s’engage à fournir l’intégralité des prestations de service indiquées sur le site Internet. Si elle ne le fait pas, les participants ont droit à un remboursement partiel au prorata des frais de participation.</p>
      <p>Dans le cas où des participants estiment qu’apiservice ne remplit pas ses obligations découlant de cette relation contractuelle, ils le font savoir par écrit ou par courriel. Faute d’une telle communication, il n’existe aucun droit à un remboursement partiel des frais de participation.</p>
      <h2>Protection des données</h2>
      <p>
      apiservice s’engage à traiter les données personnelles des participants conformément à la loi et à respecter les dispositions du droit de la protection des données. Celles-ci sont enregistrées dans une base de données sécurisée en Suisse et ne sont pas transmises à des tiers. apiservice se réserve le droit de procéder à des évaluations et à une communication publique des données sous forme anonyme.
      </p>
      <p>Des enregistrements vidéo ou audio des échanges d'expériences en ligne ne peuvent être réalisés qu’avec l’accord explicite d’apiservice et des participants.</p>
      <h2>Frais de participation</h2>
      <p>
      Les frais de participation actuellement en vigueur sont publiés sur{" "}
        <a className="inlineAnchor" href="/">
          programmedesante.apiservice.ch
        </a>
        . Ils s’entendent TVA incluse. Une facture est établie pour les frais de participation, sauf si d’autres options de paiement sont communiquées en ligne.
      </p>
      <p>Il n’existe aucun droit au remboursement des frais de participation au prorata, sauf en cas de décès ou d’incapacité à participer pour des raisons de santé, attestée par un médecin.</p>
      <h2>Retard de paiement</h2>
      <p>Tout retard de paiement entraîne l’exclusion du programme.</p>
      <h2>Durée du contrat et résiliation / exclusion</h2>
      <p>
      Le contrat est conclu pour une durée indéterminée. Il peut être résilié à tout moment et sans préavis pour la fin d’une année civile (31.12.). La résiliation doit être effectuée via la zone de connexion du programme de santé ou par écrit (par courrier ou par courriel à{" "}
        <a className="inlineAnchor" href="mailto:info@apiservice.ch">
          info@apiservice.ch
        </a>
        ).
      </p>
      <p>Une résiliation de la participation en cours d’année est exclue.</p>
      <p>apiservice est en droit de suspendre à tout moment le programme de santé. En outre, apiservice peut exclure certains participants du programme de santé sans en indiquer les raisons. Dans ce cas, les frais de participation seront remboursés au prorata.</p>
      <h2>Clause de non-responsabilité et assurance</h2>
      <p>Pour toutes les manifestations organisées par apiservice, apiservice exclut toute responsabilité pour d’éventuels dommages. Les participants sont tenus de disposer d’une couverture d’assurance suffisante.</p>
      <h2>Modification des frais de participation et des CG</h2>
      <p>Les éventuelles modifications de prix ou adaptations du programme de santé ont généralement lieu en début d’année. Les CG restent valables pendant un an.</p>
      <p>apiservice informe préalablement les participants de manière appropriée des modifications correspondantes. Si aucune résiliation n’est effectuée par les participants après cette information, les nouvelles conditions s’appliquent automatiquement.</p>
      <h2>Clause de sauvegarde</h2>
      <p>Si une disposition des présentes CG devait être invalide ou incomplète, ou si son exécution devait s’avérer impossible, la validité des autres dispositions n’en serait pas affectée. Dans un tel cas, la disposition invalide doit être remplacée par une disposition valide qui permette d’atteindre l’objectif prévu du contrat de manière juridiquement admissible et qui se rapproche le plus possible de l’intention initiale. Il en va de même en cas de lacune.</p>
      <h2>Droit applicable et for juridique</h2>
      <p>Les accords en rapport avec les présentes CG sont exclusivement soumis au droit suisse. Le for juridique pour tous les litiges en rapport avec la présente convention est - sauf disposition impérative contraire du droit de procédure civile - Appenzell.</p>
      <p>Etat : mars 2023</p>
      <p><strong>apiservice gmbh</strong><br/>Jakob Signer-Strasse 4<br/>9050 Appenzell</p>
    </div>
  );
}

export default AgbFr;
