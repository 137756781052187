import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Button from "../../components/reusable/button";

type Props = {
  showModal: boolean;
  onHide: () => void;
  onComplete: () => void;
};

function KuendigenModal(props: Props) {
  const { t, i18n } = useTranslation();

  const handleCompleteAndClose = () => {
    props.onComplete();
    props.onHide();
  };

  return (
    <>
      <Modal show={props.showModal} onHide={props.onHide} backdrop="static" keyboard={false}>
        <Modal.Body>{t("meineGesundheitsprogramme.kuendigung_bestaetigung")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t("meineGesundheitsprogramme.kuendigung_bestaetigung_nein")}</Button>
          <Button onClick={handleCompleteAndClose}>{t("meineGesundheitsprogramme.kuendigung_bestaetigung_ja")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default KuendigenModal;
