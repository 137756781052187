import { getGetFutureTermine, TerminDto } from "../../generated/api/generated-api";

export async function GetFutureTermine(imkerId: number, token: string): Promise<TerminDto[] | void> {
  try {
    const data = await getGetFutureTermine(
      { imkerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return Promise.reject();
    }
  } catch (error) {
    return Promise.reject();
  }
}
