import i18next, { t } from "i18next";
import { isLocalDevUrl } from "./DomainService";

function IsProd() {
  const isLocalDevUrl = window.location.href.includes("localhost");
  const isDevUrl = window.location.href.includes("gesundheitsprogramm-apiservice-develop.azurewebsites.net");
  const isTestUrl = window.location.href.includes("gesundheitsprogramm-apiservice-test.azurewebsites.net");

  return !isDevUrl && !isTestUrl && !isLocalDevUrl;
}

export function GetBKUebersichtLink() {
  if (isLocalDevUrl()) return "https://localhost:44397/home/index";

  if (IsProd()) {
    return t("menu.betriebskonzeptUebersichtLink");
  } else {
    return t("menu.betriebskonzeptUebersichtLink_TEST");
  }
}

export function GetBKRahmenbedinungenLink() {
  if (isLocalDevUrl()) return "https://localhost:44397/home/Grundvoraussetzung";

  if (IsProd()) {
    return t("menu.betriebskonzeptRahmenbedingungenLink");
  } else {
    return t("menu.betriebskonzeptRahmenbedingungenLink_TEST");
  }
}

export function GetBKMeineKonzepteLink() {
  if (isLocalDevUrl()) return "https://localhost:44397/Home/Overview";

  if (IsProd()) {
    return t("menu.meineKonzepteLink");
  } else {
    return t("menu.meineKonzepteLink_TEST");
  }
}

export function GetMeineDatenBearbeitenLink(imkerId: number) {
  if (isLocalDevUrl()) return `https://localhost:44397/Imkers/Edit/${imkerId}`;

  if (IsProd()) {
    if (window.location.href.includes("programmedesante.apiservice.ch")) {
      return `https://conceptexploitation.apiservice.ch/Imkers/Edit/${imkerId}`;
    } else if (window.location.href.includes("programmasalute.apiservice.ch")) {
      return `https://concettoaziendale.apiservice.ch/Imkers/Edit/${imkerId}`;
    } else {
      return `https://betriebskonzept.apiservice.ch/Imkers/Edit/${imkerId}`;
    }
  } else {
    return t("meineGesundheitsprogramme.meineDatenBearbeitenLink_TEST", { imkerId: imkerId });
  }
}
