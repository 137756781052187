import styled from "styled-components";
import { ListUl } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";

function Rahmenbedingungen() {
  const { t, i18n } = useTranslation();

  const Titel = styled.h2`
    margin-bottom: 2rem;
  `;

  const BedingungContainer = styled.div`
    display: flex;
    flex-direction: column;
  `;

  const BedingungItem = styled.div`
    display: inline-flex;
    cursor: default;
    margin-bottom:25px;
  `;

  const BedingungItemIcon = styled.div`
    display: flex;
    align-items: center;
    padding: 0 5px 0 5px;
    background-color: #fac000;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  `;

  const ListUlStyled = styled(ListUl)`
    vertical-align: middle;
  `;

  const BedingungInhalt = styled.div`
    background-color: #e6e600;
    border: 1px solid #e6e600;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    padding: 10px;

    &:hover {
      background-color: #ffff00;
    }
  `;

  const BedingungTitel = styled.p`
    font-size: larger;
    margin-bottom: 0.5rem;
  `;

  const BedingungText = styled.p`
    margin-bottom: 0.5rem;
  `;

  const BedingungItems = t("rahmenbedingungen.rahmenbedingungen", { returnObjects: true }) as { titel: string; text: string }[];
  console.log(JSON.stringify(BedingungItems));

  return (
    <BedingungContainer>
      <Titel>{t("rahmenbedingungen.titel")}</Titel>
      {BedingungItems.map((item) => (
        <BedingungItem>
          <BedingungItemIcon>
            <ListUlStyled size="20"></ListUlStyled>
          </BedingungItemIcon>
          <BedingungInhalt>
            <BedingungTitel>{item.titel}</BedingungTitel>
            <BedingungText>{item.text}</BedingungText>
          </BedingungInhalt>
        </BedingungItem>
      ))}
    </BedingungContainer>
  );
}

export default Rahmenbedingungen;
