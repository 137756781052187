import { Route, Routes } from "react-router-dom";
import styled from "styled-components";
import * as api from "./generated/api/generated-api";
import Footer from "./pages/layout/Footer";
import Header from "./pages/layout/Banner";
import TitleMenu from "./pages/layout/TitleMenu";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import AuthProvider, { AuthContext } from "./authorization/AuthProvider";
import { Helmet } from "react-helmet";
import Uebersicht from "./pages/Uebersicht";
import Rahmenbedingungen from "./pages/Rahmenbedingungen";
import Registrierung from "./pages/Registrierung/Registrierung";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";
import Agb from "./pages/agb/Agb";
import MeinProgramm from "./pages/MeinProgramm/MeinProgramm";
import { useEffect } from "react";
import Selbstdeklaration from "./pages/Selbstdeklaration/Selbstdeklaration";
import { Survey } from "survey-react-ui";
import SubNavigation from "./pages/layout/SubNavigation";

function setLanguageByURL() {
  const url = window.location.href;

  console.log("URL: " + url);

  if (url.includes("gesundheitsprogramm.apiservice.ch")) {
    console.log("Set language to DE");
    i18n.changeLanguage("de");
  } else if (url.includes("programmasalute.apiservice.ch")) {
    console.log("Set language to IT");
    i18n.changeLanguage("it");
  } else if (url.includes("programmedesante.apiservice.ch")) {
    console.log("Set language to FR");
    i18n.changeLanguage("fr");
  }
}

const AppLayout = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 900px) auto;
  grid-template-rows: min-content minmax(150px, 30vh) auto 130px;
  grid-template-areas:
    ". titlemenu ."
    "banner banner banner"
    ". content ."
    "footer footer footer";
  min-height: 100vh;
  font-family: "Myriad Pro Regular";
  white-space: pre-line;

  @media (min-width: 1001px) {
    grid-template-rows: min-content minmax(150px, 30vh) min-content auto 130px;
    grid-template-areas:
      ". titlemenu ."
      "banner banner banner"
      ". subnavigation ."
      ". content ."
      "footer footer footer";
  }
`;

const StyledContent = styled.div`
  grid-area: content;
  /* background-color: #e4d3e4; */
  padding: 15px;
`;

const DevHint = styled.span`
  color: blue;
  font-weight: bold;
  position: fixed;
  padding: 5px;
  background: yellow;
  margin-left: 20px;
  margin-top: 20px;
`;

const TestHint = styled.span`
  color: red;
  font-weight: bold;
  position: fixed;
  padding: 5px;
  background: yellow;
  margin-left: 20px;
  margin-top: 20px;
`;

function App() {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
    console.log("Development mode");
    api.defaults.baseUrl = "https://localhost:7016";
  }

  const { t, i18n } = useTranslation();

  useEffect(() => {
    setLanguageByURL();
  }, []);

  const isDevUrl = window.location.href.includes("gesundheitsprogramm-apiservice-develop.azurewebsites.net");
  const isTestUrl = window.location.href.includes("gesundheitsprogramm-apiservice-test.azurewebsites.net");

  return (
    <AppLayout>
      <Helmet>
        <title>{t("website.title")}</title>
        <meta name={t("website.metaName") as string} content={t("website.metaContent") as string}></meta>
      </Helmet>
      <AuthProvider>
        {isDevUrl && (
          <>
            <br />
            <DevHint>ENTWICKLUNG</DevHint>
          </>
        )}
        {isTestUrl && (
          <>
            <br />
            <TestHint>TESTUMGEBUNG</TestHint>
          </>
        )}
        <Header></Header>
        <TitleMenu></TitleMenu>
        <SubNavigation></SubNavigation>
        <StyledContent>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route index element={<Uebersicht />} />
            <Route path="/rahmenbedingungen" element={<Rahmenbedingungen />}></Route>
            <Route path="/conditionscadre" element={<Rahmenbedingungen />}></Route>
            <Route path="/condizioniquadro" element={<Rahmenbedingungen />}></Route>
            <Route path="/rahmenbedingungen" element={<Rahmenbedingungen />}></Route>
            <Route path="/agb" element={<Agb />}></Route>
            <Route path="/conditionsdeparticipation" element={<Rahmenbedingungen />}></Route>
            <Route path="/criteridiammissione" element={<Rahmenbedingungen />}></Route>
            <Route path="/neuanmeldung/*" element={<Registrierung />}></Route>
            <Route path="/inscription/*" element={<Registrierung />}></Route>
            <Route path="/iscrizione/*" element={<Registrierung />}></Route>
            <Route path="/meinprogramm" element={<MeinProgramm />}></Route>
            <Route path="/monprogramme" element={<MeinProgramm />}></Route>
            <Route path="/ilmioprogramma" element={<MeinProgramm />}></Route>
            <Route path="/selbstdeklaration/:id" element={<Selbstdeklaration />}></Route>
            <Route path="/admin" element={<Admin />}></Route>
            <Route path="/admin/imker-pruefen" element={"Admin_Neu angemeldete Imker prüfen"}></Route>
            <Route path="/admin/deklarationen-pruefen" element={"Admin_Deklarationen prüfen"}></Route>
            <Route path="/admin/imker-verwalten" element={"Admin_Imker verwalten"}></Route>
            <Route path="/admin/fragenkatalog-verwalten" element={"Admin_Fragenakatalog verwalten"}></Route>
            <Route path="/admin/termine" element={"Admin_Termine"}></Route>
            <Route path="/admin/reporting" element={"Admin_Reporting"}></Route>
            <Route path="/admin/exporte" element={"Admin_Exporte"}></Route>
          </Routes>
        </StyledContent>
        <Footer></Footer>
      </AuthProvider>
    </AppLayout>
  );
}

export default App;
