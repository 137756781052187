import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { AuthContext, SignInResponse } from "../authorization/AuthProvider";
import Alert from "../components/reusable/alert";
import Button from "../components/reusable/button";
import { RedirectToHiddenLogin } from "../services/HiddenLogin";

function Login() {
  const { t, i18n } = useTranslation();
  let auth = React.useContext(AuthContext);


  const ButtonRow = styled.div`
    margin-top: 10px;
  `;
  const PrivacyText = styled.p`
    margin-top: 30px;
  `;

  const MyInput = styled.input`
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    /* background-clip: padding-box; */
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  `;

  const MyLabel = styled.label`
    margin-top: 10px;
  `;

  const [loginError, setLoginError] = useState(false);
  const [loading, setLoading] = useState(false);

  let navigate = useNavigate();

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setLoading(true);

    let formData = new FormData(event.currentTarget);
    let email = formData.get("email") as string;
    let password = formData.get("password") as string;

    auth.signin(email, password, (response: SignInResponse) => {
      setLoading(false);
      // admin: redirect to admin page
      if (response.isUnauthorized) {
        setLoginError(true);
      } else {
        setLoginError(false);
        RedirectToHiddenLogin(response.userId);
      }
    });
  }

  function resetPassword() {
    const isDevUrl = window.location.href.includes("gesundheitsprogramm-apiservice-develop.azurewebsites.net") || window.location.href.includes("localhost");
    const isTestUrl = window.location.href.includes("gesundheitsprogramm-apiservice-test.azurewebsites.net");

    if (isDevUrl || isTestUrl) {
      window.location.href = "https://betriebskonzept-apiservice-test.azurewebsites.net/home/Login_Pwdreset";
    } else {
      if (i18n.language === "de") window.location.href = "https://betriebskonzept.apiservice.ch/home/Login_Pwdreset";
      if (i18n.language === "fr") window.location.href = "https://conceptexploitation.apiservice.ch/home/Login_Pwdreset";
      if (i18n.language === "it") window.location.href = "https://concettoaziendale.apiservice.ch/home/Login_Pwdreset";
    }
  }

  // TODO: Form mit bootstrap machen

  return (
    <div>
      <h2>{t("login.topText")}</h2>
      <h4>Login</h4>
      <Alert show={loginError}>{t("login.failed")}</Alert>
      <form onSubmit={handleSubmit}>
        <MyLabel htmlFor="email">{t("login.email")}</MyLabel>
        <MyInput id="email" name="email" type="text" />
        <MyLabel htmlFor="password">{t("login.passwort")}</MyLabel>
        <MyInput id="password" name="password" type="password" />
        <ButtonRow>
          {loading ? (
            <Button type="submit" disabled>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />
            </Button>
          ) : (
            <Button type="submit">Login</Button>
          )}

          <Button type="button" onClick={resetPassword}>
            {t("login.passwordReset")}
          </Button>
        </ButtonRow>
      </form>
      <PrivacyText>{t("login.privacyText")}</PrivacyText>
    </div>
  );
}

export default Login;
