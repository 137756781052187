import { getGetSelbstdeklaration, getGetSelbstdeklarationenListe, ImkerSelbstdeklarationListItemDto, postCompleteSeblstdeklaration, postCreateNewSelbstdeklaration, postUpdateSelbstdeklaration, SelbstdeklarationDto, SelbstdeklarationUpdateDto } from "../../generated/api/generated-api";

export async function GetImkerSelbstdeklarationListe(imkerId: number, token: string): Promise<ImkerSelbstdeklarationListItemDto[] | void> {
  try {
    const data = await getGetSelbstdeklarationenListe(
      { imkerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return Promise.reject();
    }
  } catch (error) {
    return Promise.reject();
  }
}


export async function CreateNewSelbstdeklaration(imkerId: number, token: string): Promise<number | void> {
  try {
    const data = await postCreateNewSelbstdeklaration(
      { idImker: imkerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return Promise.reject();
    }
  } catch (error) {
    return Promise.reject();
  }
}

export async function GetSelbstdeklaration(id: number, token: string): Promise<SelbstdeklarationDto | void> {
  try {
    const data = await getGetSelbstdeklaration(
      { id: id },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return Promise.reject();
    }
  } catch (error) {
    return Promise.reject();
  }
}


export async function UdpateSelbstdeklaration(selbstdeklarationId: number, json: string, token: string) {
  try {
    const data = await postUpdateSelbstdeklaration(
      { selbstdeklarationId: selbstdeklarationId, json: json },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return Promise.reject();
    }
  } catch (error) {
    return Promise.reject();
  }
}

export async function CompleteSelbstdeklaration(selbstdeklarationId: number, json: string, token: string) {
  try {
    const data = await postCompleteSeblstdeklaration(
      { selbstdeklarationId: selbstdeklarationId, json: json },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.status === 200) {
      return data.data;
    } else {
      return Promise.reject();
    }
  } catch (error) {
    return Promise.reject();
  }
}
