import styled from "styled-components";
import { Trans, useTranslation } from "react-i18next";

function AgbDe() {
  const { t, i18n } = useTranslation();

  const Titel = styled.h2`
    margin-bottom: 2rem;
  `;

  return (
    <div>
      <Titel>AGB für die Teilnahme am Gesundheitsprogramm</Titel>
      <h2>Vertragsabschluss</h2>
      <p>
        Mit der Online-Anmeldung auf{" "}
        <a className="inlineAnchor" href="/">
          gesundheitsprogramm.apiservice.ch
        </a>{" "}
        schliessen die Teilnehmenden einen Vertrag mit der apiservice gmbh (Programmanbieterin) und erklären sich mit den vorliegenden AGB einverstanden. Die Teilnehmenden bestätigen, die publizierten Teilnahmebedingungen gelesen zu haben und stimmen diesen zu.
      </p>
      <h2>Pflichten der Teilnehmenden</h2>
      <p>
        Die Teilnehmenden verpflichten sich explizit
        <ul>
          <li>
            nach dem Betriebskonzept zu imkern und die{" "}
            <a className="inlineAnchor" href="/rahmenbedingungen">
              Rahmenbedingungen
            </a>{" "}
            sowie Empfehlungen des Bienengesundheitsdienstes (BGD) einzuhalten;
          </li>
          <li>ihre imkerlichen Aktivitäten auf der online verfügbaren jährlichen Selbstdeklaration wahrheitsgemäss zu erfassen;</li>
          <li>zur Bezahlung der Teilnahmegebühr innert einer Zahlungsfrist von 30 Tagen.</li>
        </ul>
      </p>
      <p>apiservice prüft die Selbstdeklaration auf Einhaltung des BGD-Betriebskonzepts und der Rahmenbedingungen des Gesundheitsprogramms. Bei Einhaltung dieser Vorgaben werden die Teilnehmenden mit der Plakette «anerkannte zeitgemässe Bienenhaltung» ausgezeichnet.</p>
      <p>Wer diese Anforderungen nicht erfüllt, erhält keine Anerkennung. Ein Anspruch auf Rückerstattung der Kursgebühren besteht nicht.</p>
      <p>Nicht genutzte Dienstleistungen können weder nachgeholt noch an Dritte übertragen werden und es erfolgt keine entsprechende Rückerstattung.</p>
      <h2>Pflichten der Programmanbieterin</h2>
      <p>apiservice verpflichtet sich, die auf der Internetseite angegebenen Dienstleistungen vollumfänglich zu erbringen. Tut sie dies nicht, haben die Teilnehmenden das Recht auf eine anteilmässige Teilrückerstattung der Teilnahmegebühr.</p>
      <p>Im Falle, dass Teilnehmende der Meinung sind, apiservice komme ihren Pflichten aus diesem Vertragsverhältnis nicht nach, teilen sie dies schriftlich oder per Mail mit. Ohne entsprechende Mitteilung besteht kein Anspruch auf Teilrückerstattung der Teilnahmegebühr.</p>
      <h2>Datenschutz</h2>
      <p>
        apiservice verpflichtet sich, die Personendaten der Teilnehmenden gesetzeskonform zu bearbeiten und die Bestimmungen des Datenschutzrechts einzuhalten. Die Daten werden in einer sicheren Datenbank in der Schweiz abgespeichert und nicht an Dritte weitergegeben. apiservice behält sich
        Auswertungen und öffentliche Kommunikation der Daten in anonymisierter Form vor.
      </p>
      <p>Video- oder Audio-Aufnahmen von online-Erfahrungsaustauschen dürfen nur mit ausdrücklichem Einverständnis sowohl von apiservice wie auch von Teilnehmenden gemacht werden.</p>
      <h2>Teilnahmegebühr</h2>
      <p>
        Die aktuell geltende Teilnahmegebühr wird auf{" "}
        <a className="inlineAnchor" href="/">
          gesundheitsprogramm.apiservice.ch
        </a>{" "}
        publiziert. Sie versteht sich inklusive Mehrwertsteuer. Die Teilnahmegebühr wird in Rechnung gestellt, soweit keine andere Zahlungsoptionen online kommuniziert sind.
      </p>
      <p>Es besteht kein Anspruch auf Erstattung der anteilsmässigen Teilnahmegebühr, ausser bei Todesfall oder bei ärztlich bestätigter Unfähigkeit an der Teilnahme aus gesundheitlichen Gründen.</p>
      <h2>Zahlungsverzug</h2>
      <p>Bei Zahlungsverzug erfolgt der Ausschluss aus der Programmteilnahme.</p>
      <h2>Vertragsdauer und Kündigung bzw. Ausschluss</h2>
      <p>
        Der Vertragsabschluss erfolgt auf unbestimmte Dauer. Er kann jederzeit und ohne Einhaltung einer Kündigungsfrist auf das Ende eines Kalenderjahres gekündigt werden (31.12.). Die Kündigung hat über den Login-Bereich des Gesundheitsprogramms oder schriftlich (per Post oder E-Mail an{" "}
        <a className="inlineAnchor" href="mailto:info@apiservice.ch">
          info@apiservice.ch
        </a>
        ) zu erfolgen.
      </p>
      <p>Eine unterjährige Kündigung der Teilnahme ist ausgeschlossen.</p>
      <p>apiservice ist berechtigt, das Gesundheitsprogramm jederzeit einzustellen. Zudem kann apiservice einzelne Teilnehmende ohne Angabe von Gründen aus dem Gesundheitsprogramm ausschliessen. Diesfalls erhalten Teilnehmende die Teilnahmegebühr anteilsmässig erstattet.</p>
      <h2>Haftungsausschluss und Versicherung</h2>
      <p>Für alle von apiservice organisierten Veranstaltungen schliesst apiservice jegliche Haftung für allfällig entstandene Schäden aus. Teilnehmende sind für eine ausreichende Versicherungsdeckung verantwortlich.</p>
      <h2>Änderung der Teilnahmegebühr und der AGB</h2>
      <p>Allfällige Änderungen der Preise oder Anpassungen am Gesundheitsprogramm erfolgen im Regelfall auf Jahresbeginn. Die AGB behalten ihre Gültigkeit jeweilen für ein Jahr.</p>
      <p>apiservice informiert die Teilnehmenden in geeigneter Weise vorgängig über entsprechende Änderungen. Erfolgt nach der Information keine Kündigung durch die Teilnahmenden, gelten automatisch die neuen Konditionen.</p>
      <h2>Salvatorische Klausel</h2>
      <p>Sollte eine Bestimmung dieser AGB unwirksam oder unvollständig sein oder sollte die Erfüllung unmöglich werden, so werden dadurch die Wirksamkeit der übrigen Teile nicht beeinträchtigt. In einem solchen Fall ist die unwirksame Bestimmung durch eine zulässig wirksame Bestimmung zu ersetzen, durch die der beabsichtigte Vertragszweck in rechtlich zulässiger Weise erreicht werden kann und die nach ihrem Inhalt der ursprünglichen Absicht am nächsten kommt. Gleiches gilt im Falle einer Lücke.</p>
      <h2>Anwendbares Recht und Gerichtsstand </h2>
      <p>Die Vereinbarungen im Zusammenhang mit diesen AGB unterstehen ausschliesslich schweizerischem Recht. Gerichtsstand für alle im Zusammenhang mit der vorliegenden Vereinbarung stehenden Streitigkeiten ist – soweit nicht durch das Zivilprozessrecht anders zwingend bestimmt – Appenzell.</p>
      <p>Stand März 2023</p>
      <p><strong>apiservice gmbh</strong><br/>Jakob Signer-Strasse 4<br/>9050 Appenzell</p>
    </div>
  );
}

export default AgbDe;
