import { useContext, useState } from "react";
import { List } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { bool } from "yup";
import { GetLanguageMenu } from "./LanguageMenu";
import { GetBKMeineKonzepteLink, GetBKRahmenbedinungenLink, GetBKUebersichtLink } from "../../services/BetriebskonzeptLinks";
import { AuthContext } from "../../authorization/AuthProvider";

const StyledMobileMenu = styled.div<{ visible: boolean }>`
  display: ${(props) => (props.visible ? "grid" : "none")};
  grid-row: 2 / 3;
  grid-column: 1 / 4;
  background-color: #fdc300;
  font-size: 1.2rem;

  @media (min-width: 1001px) {
    display: none;
  }
`;

const MenuItemContainer = styled.div`
  padding-left: 10px;
  line-height: 2.5rem;
  text-transform: uppercase;
`;

const MenuItem = styled.div`
  a {
    text-decoration: none;
    color: inherit;
  }
`;

const SubMenuItem = styled.div<{ visible: boolean }>`
  padding-left: 15px;

  a {
    text-decoration: none;
    color: inherit;
  }

  display: ${(props) => (props.visible ? "grid" : "none")};
`;

interface Props {
  show: boolean;
  onClickLink: () => void;
  logout: () => void;
}

function MobileMenu(props: Props) {
  const { t, i18n } = useTranslation();
  let languageMenu = GetLanguageMenu(i18n.language);
  let auth = useContext(AuthContext);
  const user = auth.user;
  
  function changeLanguage(lang: string) {
    i18n.changeLanguage(lang);
    props.onClickLink();
  }

  function logout() {
    props.onClickLink();
    props.logout();
  }

  return (
    <StyledMobileMenu visible={props.show}>
      <MenuItemContainer>
        <MenuItem>
          <a href={GetBKUebersichtLink().toString()}>{t("menu.betriebskonzept")}</a>
        </MenuItem>
        <SubMenuItem visible={true}>
          <a href={GetBKUebersichtLink().toString()}>{t("menu.uebersicht")}</a>
        </SubMenuItem>
        <SubMenuItem visible={true}>
          <a href={GetBKRahmenbedinungenLink().toString()}>{t("menu.rahmenbedingungen")}</a>
        </SubMenuItem>
        <SubMenuItem visible={true}>
          <a href={GetBKMeineKonzepteLink().toString()}>{t("menu.meineKonzepte")}</a>
        </SubMenuItem>
      </MenuItemContainer>
      <MenuItemContainer>
        <MenuItem>{t("menu.gesundheitsprogramm")}</MenuItem>
        <SubMenuItem visible={true}>
          <Link to="/" onClick={props.onClickLink}>
            {t("menu.uebersicht")}
          </Link>
        </SubMenuItem>
        <SubMenuItem visible={true}>
          <Link to="/rahmenbedingungen" onClick={props.onClickLink}>
            {t("menu.rahmenbedingungen")}
          </Link>
        </SubMenuItem>
        <SubMenuItem visible={true}>
          <Link to="/neuanmeldung" onClick={props.onClickLink}>
            {t("menu.neuanmeldung")}
          </Link>
        </SubMenuItem>
        <SubMenuItem visible={true}>
          <Link to="/meinprogramm" onClick={props.onClickLink}>
            {t("menu.selbstdeklarationen")}
          </Link>
        </SubMenuItem>
      </MenuItemContainer>
      <MenuItemContainer>
        <MenuItem>{languageMenu.SelectedLanguageText}</MenuItem>
        <SubMenuItem visible={true} onClick={() => changeLanguage(languageMenu.MenuItem1)}>
          {languageMenu.MenuItem1Text}
        </SubMenuItem>
        <SubMenuItem visible={true} onClick={() => changeLanguage(languageMenu.MenuItem2)}>
          {languageMenu.MenuItem2Text}
        </SubMenuItem>
      </MenuItemContainer>
      <MenuItemContainer>
        {user && <MenuItem onClick={logout}>Logout</MenuItem>}
        {!user && (
          <MenuItem>
            <Link to="/login" onClick={props.onClickLink}>Login</Link>
          </MenuItem>
        )}
        <MenuItem></MenuItem>
      </MenuItemContainer>
    </StyledMobileMenu>
  );
}

export default MobileMenu;
