import { FormikProps } from "formik";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Alert from "../../components/reusable/alert";
import { MyFormData } from "./MyFormData";

interface RegistrierungEmailProps extends FormikProps<MyFormData> {
  showGesundheitsprogrammHinweis: boolean
}

function RegistrierungEmail(props: RegistrierungEmailProps) {
  const { t, i18n } = useTranslation();
  
  const EMailHinweis = styled.p`
    color: red;
  `;

  const { values, handleChange, handleBlur, errors } = props;
  return (
    <>
      <EMailHinweis>{t("anmeldung.emailHinweis")}</EMailHinweis>
      <Row className="mb-2">
        <Form.Group as={Col} md="6">
          <Form.Label>Email</Form.Label>
          <Form.Control name="email" type="email" value={values.email} onChange={handleChange} onBlur={handleBlur} isInvalid={!!errors.email}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Alert show={props.showGesundheitsprogrammHinweis} style={{marginBottom: "5px"}}>{t("anmeldung.gesundheitsprogrammHinweis")}</Alert>
    </>
  );
}

export default RegistrierungEmail;
