import { form } from "oazapfts/lib/runtime/query";
import { Anrede as gAnrede, Land as gLand, Hoehe as gHoehe, Kanton as gKanton, ImkerRegistrierungDto, postImkerRegistrierung, ImkerRegistrierungDtOmitStatus, getGetImkerRegistrierung, ImkerStatus } from "../../generated/api/generated-api";
import { Anrede, MyFormData, Sprache, Land, Beutensystem, Hoehe, Kanton } from "../../pages/Registrierung/MyFormData";

export async function RegistrierungImker(values: MyFormData, betriebskonzeptImker: boolean, teilnahmeErneuern: boolean) {
  let imkerRegistrierung: ImkerRegistrierungDto = {};

  imkerRegistrierung.betriebskonzeptImker = betriebskonzeptImker;
  imkerRegistrierung.teilnahme_Erneuern = teilnahmeErneuern;

  if (!betriebskonzeptImker) {
    imkerRegistrierung.vorname = values.vorname;
    imkerRegistrierung.nachname = values.nachname;
    imkerRegistrierung.password = values.password;
  }

  imkerRegistrierung.strasseNr = values.strasseNr;
  imkerRegistrierung.plz = values.plz;
  imkerRegistrierung.ort = values.ort;

  imkerRegistrierung.eMail = values.email;
  imkerRegistrierung.sprache = convertSprache(values.sprache);
  imkerRegistrierung.anrede = convertAnrede(values.anrede);
  imkerRegistrierung.land = convertLand(values.land);
  imkerRegistrierung.tel = values.tel;
  imkerRegistrierung.imker_SeitWann = values.imker_seitWann;
  imkerRegistrierung.imker_AnzahlVoelker = values.imker_anzahlVoelker;
  imkerRegistrierung.imker_Beutensystem_Schweizerkasten = values.imker_beutensystem_schweizerkasten;
  imkerRegistrierung.imker_Beutensystem_Magazin = values.imker_beutensystem_magazin;
  imkerRegistrierung.imker_Varroaunterlage = values.imker_varroaunterlage;
  imkerRegistrierung.imker_HerausnehmbareWaben = values.imker_herausnehmbareWaben;
  imkerRegistrierung.imker_Hoehe = convertHoehe(values.imker_hoehe);
  imkerRegistrierung.imker_MitgliedVerein = values.imker_mitgliedVerein;
  imkerRegistrierung.imker_Sektion = values.imker_sektion;
  imkerRegistrierung.imker_Kanton = convertKanton(values.imker_kanton);
  imkerRegistrierung.imker_KickoffTermin = values.imker_kickoffTermin;

  await postImkerRegistrierung(imkerRegistrierung)
    .then((data) => {
      console.log(data);
      if (data.status === 400 || data.status === 500) {
        console.log("Error");
        return Promise.reject();
      }
      if (data.status === 201) {
        console.log("Created");
        return Promise.resolve();
      }
    })
    .catch((error) => {
      //TODO: Error handling, Anzeige UI
      console.log(error);
      return Promise.reject();
    });
}

export async function GetImkerRegistrierung(imkerId: number, token: string): Promise<{ data: MyFormData; status: ImkerStatus } | void> {
  try {
    const data = await getGetImkerRegistrierung(
      { id: imkerId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    if (data.status === 200) {
      let formData: MyFormData = {
        vorname: data.data.vorname!,
        nachname: data.data.nachname!,
        strasseNr: data.data.strasseNr!,
        plz: data.data.plz!,
        ort: data.data.ort!,
        email: data.data.eMail!,
        emailValidation: data.data.eMail!,
        sprache: convertSprache_(data.data.sprache!),
        anrede: convertAnrede_(data.data.anrede!),
        land: convertLand_(data.data.land!),
        tel: data.data.tel!,
        telValidation: data.data.tel!,
        imker_seitWann: data.data.imker_SeitWann!,
        imker_anzahlVoelker: data.data.imker_AnzahlVoelker!,
        imker_beutensystem_schweizerkasten: data.data.imker_Beutensystem_Schweizerkasten!,
        imker_beutensystem_magazin: data.data.imker_Beutensystem_Magazin!,
        imker_varroaunterlage: data.data.imker_Varroaunterlage!,
        imker_herausnehmbareWaben: data.data.imker_HerausnehmbareWaben!,
        imker_hoehe: convertHoehe_(data.data.imker_Hoehe!),
        imker_mitgliedVerein: data.data.imker_MitgliedVerein!,
        imker_sektion: data.data.imker_Sektion!,
        imker_kanton: convertKanton_(data.data.imker_Kanton!),
        password: "",
        passwordValidation: "",
        beutenSystemAuswahl: "",
        imker_kickoffTermin: undefined,
        bestaetigung_agb: false,
        bestaetigung_kostenpflichtig: false,
      };

      return { data: formData, status: data.data.imkerStatus! };
    } else {
      return Promise.reject();
    }
  } catch (error) {
    return Promise.reject();
  }
}

function convertSprache(sprache: Sprache | undefined): string | undefined {
  if (sprache === Sprache.de) return "de";
  if (sprache === Sprache.fr) return "fr";
  if (sprache === Sprache.it) return "it";
}

function convertSprache_(sprache: string): Sprache {
  if (sprache === "fr") return Sprache.fr;
  if (sprache === "it") return Sprache.it;
  return Sprache.de;
}

function convertAnrede(anrede: Anrede | undefined): gAnrede | undefined {
  if (anrede === Anrede.m) return 1;
  if (anrede === Anrede.f) return 2;
}

function convertAnrede_(anrede: gAnrede): Anrede | undefined {
  if (anrede === 1) return Anrede.m;
  if (anrede === 2) return Anrede.f;
}

function convertLand(land: Land | undefined): gLand | undefined {
  if (land === Land.ch) return 1;
  if (land === Land.li) return 2;
  if (land === Land.xx) return 3;
}

function convertLand_(land: gLand): Land | undefined {
  if (land === 1) return Land.ch;
  if (land === 2) return Land.li;
  if (land === 3) return Land.xx;
}

function convertHoehe(hoehe: Hoehe | undefined): gHoehe | undefined {
  if (hoehe === Hoehe.bis600) return 1;
  if (hoehe === Hoehe._601bis900) return 2;
  if (hoehe === Hoehe._901bis1200) return 3;
  if (hoehe === Hoehe.ueber1200) return 4;
}

function convertHoehe_(hoehe: gHoehe): Hoehe | undefined {
  if (hoehe === 1) return Hoehe.bis600;
  if (hoehe === 2) return Hoehe._601bis900;
  if (hoehe === 3) return Hoehe._901bis1200;
  if (hoehe === 4) return Hoehe.ueber1200;
}

function convertKanton(imker_kanton: Kanton | undefined): gKanton | undefined {
  if (imker_kanton === Kanton.AG) return 1;
  if (imker_kanton === Kanton.AI) return 2;
  if (imker_kanton === Kanton.AR) return 3;
  if (imker_kanton === Kanton.BE) return 4;
  if (imker_kanton === Kanton.BL) return 5;
  if (imker_kanton === Kanton.BS) return 6;
  if (imker_kanton === Kanton.FR) return 7;
  if (imker_kanton === Kanton.GE) return 8;
  if (imker_kanton === Kanton.GL) return 9;
  if (imker_kanton === Kanton.GR) return 10;
  if (imker_kanton === Kanton.JU) return 11;
  if (imker_kanton === Kanton.LU) return 12;
  if (imker_kanton === Kanton.NE) return 13;
  if (imker_kanton === Kanton.NW) return 14;
  if (imker_kanton === Kanton.OW) return 15;
  if (imker_kanton === Kanton.SG) return 16;
  if (imker_kanton === Kanton.SH) return 17;
  if (imker_kanton === Kanton.SO) return 18;
  if (imker_kanton === Kanton.SZ) return 19;
  if (imker_kanton === Kanton.TG) return 20;
  if (imker_kanton === Kanton.TI) return 21;
  if (imker_kanton === Kanton.UR) return 22;
  if (imker_kanton === Kanton.VD) return 23;
  if (imker_kanton === Kanton.VS) return 24;
  if (imker_kanton === Kanton.ZG) return 25;
  if (imker_kanton === Kanton.ZH) return 26;
  if (imker_kanton === Kanton.Andere) return 27;
  if (imker_kanton === Kanton.Liechtenstein) return 28;
}

function convertKanton_(imker_kanton: gKanton): Kanton | undefined {
  if (imker_kanton === 1) return Kanton.AG;
  if (imker_kanton === 2) return Kanton.AI;
  if (imker_kanton === 3) return Kanton.AR;
  if (imker_kanton === 4) return Kanton.BE;
  if (imker_kanton === 5) return Kanton.BL;
  if (imker_kanton === 6) return Kanton.BS;
  if (imker_kanton === 7) return Kanton.FR;
  if (imker_kanton === 8) return Kanton.GE;
  if (imker_kanton === 9) return Kanton.GL;
  if (imker_kanton === 10) return Kanton.GR;
  if (imker_kanton === 11) return Kanton.JU;
  if (imker_kanton === 12) return Kanton.LU;
  if (imker_kanton === 13) return Kanton.NE;
  if (imker_kanton === 14) return Kanton.NW;
  if (imker_kanton === 15) return Kanton.OW;
  if (imker_kanton === 16) return Kanton.SG;
  if (imker_kanton === 17) return Kanton.SH;
  if (imker_kanton === 18) return Kanton.SO;
  if (imker_kanton === 19) return Kanton.SZ;
  if (imker_kanton === 20) return Kanton.TG;
  if (imker_kanton === 21) return Kanton.TI;
  if (imker_kanton === 22) return Kanton.UR;
  if (imker_kanton === 23) return Kanton.VD;
  if (imker_kanton === 24) return Kanton.VS;
  if (imker_kanton === 25) return Kanton.ZG;
  if (imker_kanton === 26) return Kanton.ZH;
  if (imker_kanton === 27) return Kanton.Andere;
  if (imker_kanton === 28) return Kanton.Liechtenstein;
}
