import { FormikProps } from "formik";
import { useEffect, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Anrede, Land, MyFormData, Sprache } from "./MyFormData";

export type BetriebskonzeptImkerData = {
  betriebskonzeptImker: boolean;
  vorname: string;
  nachname: string;
  strasseNr: string;
  plz: number | undefined;
  ort: string;
};

interface StammdatenProps extends FormikProps<MyFormData> {
  betriebskonzeptImkerData: BetriebskonzeptImkerData | undefined;
  teilnahmeErneuern: boolean;
}

export function RegistrierungStammdaten(props: StammdatenProps) {
  const { t, i18n } = useTranslation();

  const { values, handleChange, errors } = props;
  const [ strasseReadonly, setStrasseReadonly ] = useState(false);
  const [ PLZReadonly, setPLZReadonly ] = useState(false);
  const [ ortReadonly, setOrtReadonly ] = useState(false);

  if (props.betriebskonzeptImkerData?.betriebskonzeptImker) {
    console.log("Prefill betriebskonzept data");
    values.vorname = props.betriebskonzeptImkerData.vorname;
    values.nachname = props.betriebskonzeptImkerData.nachname;
    
    if (props.betriebskonzeptImkerData.strasseNr) {
      values.strasseNr = props.betriebskonzeptImkerData.strasseNr;
    }
    if (props.betriebskonzeptImkerData.plz !== 0) {
      values.plz = props.betriebskonzeptImkerData.plz;
    }
    if (props.betriebskonzeptImkerData.ort) {
      values.ort = props.betriebskonzeptImkerData.ort;
    }

    console.log(JSON.stringify(values));
  }

  useEffect(() => {
    if (props.betriebskonzeptImkerData?.betriebskonzeptImker) {
      if (values.strasseNr) setStrasseReadonly(true);
      if (values.plz) setPLZReadonly(true);
      if (values.ort) setOrtReadonly(true);
    }
  }, []);

  return (
    <>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.sprache")}</Form.Label>
          <Form.Select name="sprache" value={values.sprache} onChange={handleChange} isInvalid={!!errors.sprache}>
            <option value=""></option>
            {Object.keys(Sprache).map((key) => (
              <option key={key} value={Sprache[key as keyof typeof Sprache]}>
                {t(Sprache[key as keyof typeof Sprache])}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors.sprache}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.anrede")}</Form.Label>
          <Form.Select name="anrede" value={values.anrede} onChange={handleChange} isInvalid={!!errors.anrede}>
            <option value=""></option>
            {Object.keys(Anrede).map((key) => (
              <option key={key} value={Anrede[key as keyof typeof Anrede]}>
                {t(Anrede[key as keyof typeof Anrede])}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors.anrede}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.vorname")}</Form.Label>
          <Form.Control name="vorname" type="text" value={values.vorname} onChange={handleChange} isInvalid={!!errors.vorname} disabled={props.betriebskonzeptImkerData?.betriebskonzeptImker}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.vorname}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.nachname")}</Form.Label>
          <Form.Control name="nachname" type="text" value={values.nachname} onChange={handleChange} isInvalid={!!errors.nachname} disabled={props.betriebskonzeptImkerData?.betriebskonzeptImker}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.nachname}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.strasseNr")}</Form.Label>
          <Form.Control name="strasseNr" type="text" value={values.strasseNr} onChange={handleChange} isInvalid={!!errors.strasseNr} disabled={strasseReadonly}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.strasseNr}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="2">
          <Form.Label>{t("anmeldung.plz")}</Form.Label>
          <Form.Control name="plz" type="text" value={values.plz} onChange={handleChange} isInvalid={!!errors.plz} disabled={PLZReadonly}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.plz}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="6">
          <Form.Label>{t("anmeldung.ort")}</Form.Label>
          <Form.Control name="ort" type="text" value={values.ort} onChange={handleChange} isInvalid={!!errors.ort} disabled={ortReadonly}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.ort}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.land")}</Form.Label>
          <Form.Select name="land" value={values.land} onChange={handleChange} isInvalid={!!errors.land}>
            <option value=""></option>
            {Object.keys(Land).map((key) => (
              <option key={key} value={Land[key as keyof typeof Land]}>
                {t(Land[key as keyof typeof Land])}
              </option>
            ))}
          </Form.Select>
          <Form.Control.Feedback type="invalid">{errors.land}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.telefon")}</Form.Label>
          <Form.Control name="tel" type="text" value={values.tel} onChange={handleChange} isInvalid={!!errors.tel}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.tel}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group as={Col} md="4">
          <Form.Label>{t("anmeldung.telefonWiederholen")}</Form.Label>
          <Form.Control name="telValidation" type="text" value={values.telValidation} onChange={handleChange} isInvalid={!!errors.telValidation}></Form.Control>
          <Form.Control.Feedback type="invalid">{errors.telValidation}</Form.Control.Feedback>
        </Form.Group>
      </Row>
      {!props.betriebskonzeptImkerData?.betriebskonzeptImker && (
        <Row className="mb-3">
          <Form.Group as={Col} md="4">
            <Form.Label>{t("anmeldung.email")}</Form.Label>
            <Form.Control name="email" type="text" value={values.email} onChange={handleChange} isInvalid={!!errors.email} disabled={true}></Form.Control>
            <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>{t("anmeldung.emailWiederholen")}</Form.Label>
            <Form.Control name="emailValidation" type="text" value={values.emailValidation} onChange={handleChange} isInvalid={!!errors.emailValidation}></Form.Control>
            <Form.Control.Feedback type="invalid">{errors.emailValidation}</Form.Control.Feedback>
          </Form.Group>
        </Row>
      )}
      {(!props.betriebskonzeptImkerData?.betriebskonzeptImker && !props.teilnahmeErneuern) && (
        <Row className="mb-3">
          <Form.Group as={Col} md="4">
            <Form.Label>{t("anmeldung.passwort")}</Form.Label>
            <Form.Control name="password" type="password" value={values.password} onChange={handleChange} isInvalid={!!errors.password}></Form.Control>
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group as={Col} md="4">
            <Form.Label>{t("anmeldung.passwortWiederholen")}</Form.Label>
            <Form.Control name="passwordValidation" type="password" value={values.passwordValidation} onChange={handleChange} isInvalid={!!errors.passwordValidation}></Form.Control>
            <Form.Control.Feedback type="invalid">{errors.passwordValidation}</Form.Control.Feedback>
          </Form.Group>
        </Row>
      )}
    </>
  );
}
