import { CircleFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { AmpelContainer } from "./Ampel";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../../authorization/AuthProvider";
import { GetMeineDatenBearbeitenLink } from "../../services/BetriebskonzeptLinks";
import Button from "../../components/reusable/button";


function StatusInValidierung() {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  let auth = useContext(AuthContext);

  const meineDatenBearbeiten = () => {
    window.location.replace(GetMeineDatenBearbeitenLink(auth.user!.id));
  }
  return (
    <>
      <AmpelContainer>
        <div>
          <CircleFill size={25} color="#ffffffab"></CircleFill>
        </div>
        <div>
          <CircleFill size={25} color="#f4fb3c"></CircleFill>
        </div>
        <div>
          <CircleFill size={25} color="#ffffffab"></CircleFill>
        </div>
      </AmpelContainer>
      <p>{t("meineGesundheitsprogramme.teilnehmerStatusInValidierung")}</p>
      <Button onClick={meineDatenBearbeiten}>{t("meineGesundheitsprogramme.meineDaten")}</Button>
    </>
  );
}

export default StatusInValidierung;

