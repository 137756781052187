import { HTMLAttributes } from "react";
import styled from "styled-components";

// --alert-color: #842029;
// --alert-bg: #f8d7da;
// --alert-border-color: #f5c2c7;

interface AlertProps extends HTMLAttributes<HTMLDivElement> {
    show: boolean;
}

const Alert = styled.div<AlertProps>`
    display: ${props => props.show ? 'block' : 'none'};
    color: #842029;
    background-color: #f8d7da;
    border: 1px solid #f5c2c7;
    transition: opacity .15s linear;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
`;

export default Alert;