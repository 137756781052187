import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Card from "../../components/reusable/card";
import { ImkerSelbstdeklarationListItemDto, ImkerStatus, SelbstdeklarationStatus } from "../../generated/api/generated-api";
import i18n from "../../i18n";
import ProgressBar from "react-bootstrap/ProgressBar";
import ButtonLink from "../../components/reusable/buttonlink";
import Button from "../../components/reusable/button";

const SelbstdeklarationListeContainer = styled(Card)`
  grid-column: 1 / 3;

  @media (max-width: 1000px) {
    grid-column: 1 / 2;
  }
`;

const CustomProgressBar = styled(ProgressBar)`
  background-color: #e0e0e0;

  .progress-bar {
    background-color: #ae8f00;
  }
`;

const CustomTable = styled.table`
  width: 100%;

  td,
  th {
    padding: 5px 10px;
  }
`;

const CustomButton = styled(Button)`
  width: 100px;
`;

function GetStatusText(status: SelbstdeklarationStatus) {
  if (status === 0 || status === 1) return i18n.t("meineGesundheitsprogramme.selbstdeklarationen_status_inBearbeitung");
  if (status === 2) return i18n.t("meineGesundheitsprogramme.selbstdeklarationen_status_inPruefung");
  if (status === 3) return i18n.t("meineGesundheitsprogramme.selbstdeklarationen_status_angenommen");
  if (status === 4) return i18n.t("meineGesundheitsprogramme.selbstdeklarationen_status_abgelehnt");
}

function SelbstdeklarationListe(props: { liste: ImkerSelbstdeklarationListItemDto[]; imkerStatus: number }) {
  const { t, i18n } = useTranslation();

  function ImkerStatusRot(imkerStatus: number) {
    return imkerStatus === 30 || imkerStatus === 40 || imkerStatus === 50;
  }

  function SelbstdeklarationReadonly(item: ImkerSelbstdeklarationListItemDto, imkerstatus: number) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const gueltigBis = new Date(item.gueltigBis!);
    const einreichenBis = new Date(item.einreichenBis!);

    if (item.selbstdeklarationStatus === 2 || item.selbstdeklarationStatus === 3 || item.selbstdeklarationStatus === 4) return true;
    if (today > gueltigBis) return true;
    
    //Imker Status rot und nicht "Gekündet durch Imker noch einreichbar"> Read only
    if (ImkerStatusRot(imkerstatus) && !(imkerstatus === 40 && today <= einreichenBis)) return true;

    return false;
  }

  function SelbstdeklarationLink(item: ImkerSelbstdeklarationListItemDto, imkerStatus: number) {
    if (ImkerStatusRot(imkerStatus) && item.selbstdeklarationId === 0) return "/selbstdeklaration/0";
    if (item.selbstdeklarationId === 0) return "/selbstdeklaration/createNew";
    return `/selbstdeklaration/${item.selbstdeklarationId}`;
  }

  return (
    <SelbstdeklarationListeContainer>
      <h2>{t("meineGesundheitsprogramme.selbstdeklarationenTitel")}</h2>
      <CustomTable style={{ width: "100%" }}>
        <tr>
          <th>{t("meineGesundheitsprogramme.selbstdeklarationen_jahr")}</th>
          <th>{t("meineGesundheitsprogramme.selbstdeklarationen_erledigungsgrad")}</th>
          <th>{t("meineGesundheitsprogramme.selbstdeklarationen_status")}</th>
          <th></th>
        </tr>
        {props.liste.map((item) => (
          <tr>
            <td>{item.jahr}</td>
            <td>
              <CustomProgressBar now={item.erledigungsgradProzent}></CustomProgressBar>
            </td>
            <td>{GetStatusText(item.selbstdeklarationStatus!)}</td>
            <td>
              {SelbstdeklarationReadonly(item, props.imkerStatus) ? (
                <CustomButton>
                  <ButtonLink to={SelbstdeklarationLink(item, props.imkerStatus)}>{t("meineGesundheitsprogramme.selbstdeklarationen_lesen")}</ButtonLink>
                </CustomButton>
              ) : (
                <CustomButton>
                  <ButtonLink to={SelbstdeklarationLink(item, props.imkerStatus)}>{t("meineGesundheitsprogramme.selbstdeklarationen_bearbeiten")}</ButtonLink>
                </CustomButton>
              )}
            </td>
          </tr>
        ))}
      </CustomTable>
    </SelbstdeklarationListeContainer>
  );
}

export default SelbstdeklarationListe;
