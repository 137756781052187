import React, { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Button from "../../components/reusable/button";

type Props = {
  showModal: boolean;
  onHide: () => void;
  onComplete: () => void;
};

function AbschliessenModal(props: Props) {
  const { t, i18n } = useTranslation();

  const handleSaveAndClose = () => {
    props.onComplete();
    props.onHide();
  };

  return (
    <>
      <Modal show={props.showModal} onHide={props.onHide} backdrop="static" keyboard={false}>
        {/* <Modal.Header closeButton>
          <Modal.Title>Modal title</Modal.Title>
        </Modal.Header> */}
        <Modal.Body>{t("meineGesundheitsprogramme.selbstdeklaration_bestaetigung")}</Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>{t("meineGesundheitsprogramme.selbstdeklaration_bestaetigung_nein")}</Button>
          <Button onClick={handleSaveAndClose}>{t("meineGesundheitsprogramme.selbstdeklaration_bestaetigung_ja")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AbschliessenModal;
