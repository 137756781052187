import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import styled from "styled-components";

const SubNavigationComponent = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }

  justify-self: right;
  grid-area: subnavigation;
`;

function SubNavigation() {
  const { t, i18n } = useTranslation();
  const location = useLocation()
  
  function isActive(path: string): boolean {
    if (path === '/' && location.pathname === '/') return true;

    if (path === '/rahmenbedingungen') {
        if (location.pathname.includes('/rahmenbedingungen')) return true;
        if (location.pathname.includes('/conditionscadre')) return true;
        if (location.pathname.includes('/condizioniquadro')) return true;
    }

    if (path === '/neuanmeldung') {
        if (location.pathname.includes('/neuanmeldung')) return true;
        if (location.pathname.includes('/inscription')) return true;
        if (location.pathname.includes('/iscrizione')) return true;
    }

    if (path === '/meinprogramm') {
        if (location.pathname.includes('/meinprogramm')) return true;
        if (location.pathname.includes('/selbstdeklaration')) return true;
    }

    return false;
  }

  return (
    <SubNavigationComponent>
      <div className="dropdown dropdownSub">
        <Link to="/" className={`dropdownLink ${isActive('/') ? 'dropdownSubActive' : ''}`}>
          {t("menu.uebersicht")}
        </Link>
      </div>
      <div className="dropdown dropdownSub">
        <Link to="/rahmenbedingungen" className={`dropdownLink ${isActive('/rahmenbedingungen') ? 'dropdownSubActive' : ''}`}>
          {t("menu.rahmenbedingungen")}
        </Link>
      </div>
      <div className="dropdown dropdownSub">
        <Link to="/neuanmeldung" className={`dropdownLink ${isActive('/neuanmeldung') ? 'dropdownSubActive' : ''}`}>
          {t("menu.neuanmeldung")}
        </Link>
      </div>
      <div className="dropdown dropdownSub">
        <Link to="/meinprogramm" className={`dropdownLink ${isActive('/meinprogramm') ? 'dropdownSubActive' : ''}`}>
          {t("menu.selbstdeklarationen")}
        </Link>
      </div>
    </SubNavigationComponent>
  );
}

export default SubNavigation;
