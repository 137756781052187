export function getDomain(): string {
  if (window.location.href.includes("localhost")) return "localhost";
  if (window.location.href.includes("azurewebsites.net")) return "azurewebsites.net";
  if (window.location.href.includes("apiservice.ch")) return "apiservice.ch";
  return "";
}

export function isLocalDevUrl() {
  return window.location.href.includes("localhost");
}
export function isDevUrl() {
  return window.location.href.includes("gesundheitsprogramm-apiservice-develop.azurewebsites.net");
}
export function isTestUrl() {
  return window.location.href.includes("gesundheitsprogramm-apiservice-test.azurewebsites.net");
}
export function isProdUrl() {
  return window.location.href.includes("gesundheitsprogramm-apiservice.azurewebsites.net") || window.location.href.includes("apiservice.ch");
}
