/**
 * Gesundheitsprogramm API
 * v1
 * DO NOT MODIFY - This file has been generated using oazapfts.
 * See https://www.npmjs.com/package/oazapfts
 */
import * as Oazapfts from "oazapfts/lib/runtime";
import * as QS from "oazapfts/lib/runtime/query";
export const defaults: Oazapfts.RequestOpts = {
    baseUrl: "/",
};
const oazapfts = Oazapfts.runtime(defaults);
export const servers = {};
export type Anrede = 1 | 2;
export type Land = 1 | 2 | 3;
export type Hoehe = 1 | 2 | 3 | 4;
export type Kanton = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28;
export type ImkerRegistrierungDto = {
    betriebskonzeptImker?: boolean;
    imkerId?: number;
    eMail?: string | null;
    sprache?: string | null;
    anrede?: Anrede;
    vorname?: string | null;
    nachname?: string | null;
    strasseNr?: string | null;
    plz?: number;
    ort?: string | null;
    land?: Land;
    tel?: string | null;
    password?: string | null;
    imker_SeitWann?: string | null;
    imker_AnzahlVoelker?: number;
    imker_Beutensystem_Schweizerkasten?: boolean;
    imker_Beutensystem_Magazin?: boolean;
    imker_Varroaunterlage?: boolean;
    imker_HerausnehmbareWaben?: boolean;
    imker_Hoehe?: Hoehe;
    imker_MitgliedVerein?: boolean;
    imker_Sektion?: string | null;
    imker_Kanton?: Kanton;
    imker_KickoffTermin?: number;
    teilnahme_Erneuern?: boolean;
};
export type KickoffterminAuswahlDto = {
    id?: number;
    text?: string | null;
};
export type GetImkerByEMailDto = {
    imker?: ImkerRegistrierungDto;
    gesundheitsprogrammAlreadyRegistered?: boolean;
    imkerFound?: boolean;
};
export type ImkerStatus = 10 | 20 | 30 | 40 | 50;
export type ImkerRegistrierungDtOmitStatus = {
    betriebskonzeptImker?: boolean;
    imkerId?: number;
    eMail?: string | null;
    sprache?: string | null;
    anrede?: Anrede;
    vorname?: string | null;
    nachname?: string | null;
    strasseNr?: string | null;
    plz?: number;
    ort?: string | null;
    land?: Land;
    tel?: string | null;
    password?: string | null;
    imker_SeitWann?: string | null;
    imker_AnzahlVoelker?: number;
    imker_Beutensystem_Schweizerkasten?: boolean;
    imker_Beutensystem_Magazin?: boolean;
    imker_Varroaunterlage?: boolean;
    imker_HerausnehmbareWaben?: boolean;
    imker_Hoehe?: Hoehe;
    imker_MitgliedVerein?: boolean;
    imker_Sektion?: string | null;
    imker_Kanton?: Kanton;
    imker_KickoffTermin?: number;
    teilnahme_Erneuern?: boolean;
    imkerStatus?: ImkerStatus;
};
export type AuthRequest = {
    email?: string | null;
    password?: string | null;
};
export type AuthResponse = {
    id?: number;
    email?: string | null;
    token?: string | null;
    isAdmin?: boolean;
    isBetriebskonzeptUser?: boolean;
};
export type ImkerDto = {
    eMail?: string | null;
    name?: string | null;
    vorname?: string | null;
    imkerStatus?: ImkerStatus;
};
export type ImkerStatusMitDatumDto = {
    imkerStatus?: ImkerStatus;
    gueltigAb?: string;
};
export type SelbstdeklarationStatus = 0 | 1 | 2 | 3 | 4;
export type ImkerSelbstdeklarationListItemDto = {
    jahr?: number;
    erledigungsgradProzent?: number;
    selbstdeklarationStatus?: SelbstdeklarationStatus;
    fragekatalogId?: number;
    selbstdeklarationId?: number | null;
    gueltigAb?: string;
    gueltigBis?: string | null;
    einreichenAb?: string | null;
    einreichenBis?: string | null;
};
export type SelbstdeklarationDto = {
    selbstdeklarationId?: number;
    selbstdeklarationJSON?: string | null;
    fragekatalogJSON?: string | null;
    status?: SelbstdeklarationStatus;
    statusBemerkung?: string | null;
    imkerId?: number;
    gueltigAb?: string;
    gueltigBis?: string | null;
    einreichenAb?: string;
    einreichenBis?: string;
};
export type SelbstdeklarationUpdateDto = {
    selbstdeklarationId?: number;
    json?: string | null;
};
export type TerminTyp = 1 | 2;
export type TerminDto = {
    terminID?: number;
    datum?: string;
    time?: string;
    terminTyp?: TerminTyp;
    adresse?: string | null;
    ort?: string | null;
    link?: string | null;
    sprache?: string | null;
};
export function postImkerRegistrierung(imkerRegistrierungDto?: ImkerRegistrierungDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 201;
        data: number;
    } | {
        status: 400;
    } | {
        status: 500;
    }>("/imkerRegistrierung", oazapfts.json({
        ...opts,
        method: "POST",
        body: imkerRegistrierungDto
    }));
}
export function getGetKickofftermine(opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: KickoffterminAuswahlDto[];
    } | {
        status: 500;
    }>("/getKickofftermine", {
        ...opts
    });
}
/**
 * Enthält nur Daten vom Betriebskonzept-Imker (keine Gesundheitsprogramm-Stammdaten)
 */
export function getBetriebskonzeptImkerByEmail({ email }: {
    email?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: GetImkerByEMailDto;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/BetriebskonzeptImkerByEmail${QS.query(QS.explode({
        email
    }))}`, {
        ...opts
    });
}
export function getGetImkerRegistrierung({ id }: {
    id?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ImkerRegistrierungDtOmitStatus;
    } | {
        status: 500;
    }>(`/GetImkerRegistrierung${QS.query(QS.explode({
        id
    }))}`, {
        ...opts
    });
}
export function postLogin(authRequest?: AuthRequest, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: AuthResponse;
    } | {
        status: 401;
    } | {
        status: 500;
    }>("/login", oazapfts.json({
        ...opts,
        method: "POST",
        body: authRequest
    }));
}
export function getGetImkerByEmail({ email }: {
    email?: string;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ImkerDto;
    } | {
        status: 500;
    }>(`/GetImkerByEmail${QS.query(QS.explode({
        email
    }))}`, {
        ...opts
    });
}
export function getGetImkerById({ id }: {
    id?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ImkerDto;
    } | {
        status: 500;
    }>(`/GetImkerById${QS.query(QS.explode({
        id
    }))}`, {
        ...opts
    });
}
export function postTeilnahmeKuendigenDurchImker({ imkerId }: {
    imkerId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText(`/TeilnahmeKuendigenDurchImker${QS.query(QS.explode({
        imkerId
    }))}`, {
        ...opts,
        method: "POST"
    });
}
export function getGetNewestImkerStatus({ imkerId }: {
    imkerId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ImkerStatusMitDatumDto;
    } | {
        status: 500;
    }>(`/GetNewestImkerStatus${QS.query(QS.explode({
        imkerId
    }))}`, {
        ...opts
    });
}
export function getGetSelbstdeklarationenListe({ imkerId }: {
    imkerId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: ImkerSelbstdeklarationListItemDto[];
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/GetSelbstdeklarationenListe${QS.query(QS.explode({
        ImkerId: imkerId
    }))}`, {
        ...opts
    });
}
export function getGetSelbstdeklaration({ id }: {
    id?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: SelbstdeklarationDto;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/GetSelbstdeklaration${QS.query(QS.explode({
        id
    }))}`, {
        ...opts
    });
}
export function postCreateNewSelbstdeklaration({ idImker }: {
    idImker?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: number;
    } | {
        status: 401;
    } | {
        status: 500;
    }>(`/CreateNewSelbstdeklaration${QS.query(QS.explode({
        idImker
    }))}`, {
        ...opts,
        method: "POST"
    });
}
export function postUpdateSelbstdeklaration(selbstdeklarationUpdateDto?: SelbstdeklarationUpdateDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/UpdateSelbstdeklaration", oazapfts.json({
        ...opts,
        method: "POST",
        body: selbstdeklarationUpdateDto
    }));
}
export function postCompleteSeblstdeklaration(selbstdeklarationUpdateDto?: SelbstdeklarationUpdateDto, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchText("/CompleteSeblstdeklaration", oazapfts.json({
        ...opts,
        method: "POST",
        body: selbstdeklarationUpdateDto
    }));
}
export function getGetFutureTermine({ imkerId }: {
    imkerId?: number;
} = {}, opts?: Oazapfts.RequestOpts) {
    return oazapfts.fetchJson<{
        status: 200;
        data: TerminDto[];
    } | {
        status: 500;
    }>(`/GetFutureTermine${QS.query(QS.explode({
        ImkerId: imkerId
    }))}`, {
        ...opts
    });
}
