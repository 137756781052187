import { getBetriebskonzeptImkerByEmail, GetImkerByEMailDto } from "../../generated/api/generated-api";

export async function FindImkerByEMail(email: string): Promise<GetImkerByEMailDto> {
    try {
        const data = await getBetriebskonzeptImkerByEmail({email: email});
        if (data.status === 200) {
            return data.data;
        } else {
            throw new Error('Error');
        }
    } catch (error) {
        console.log(error);
        return Promise.reject();
    }
}

