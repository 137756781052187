export function RedirectToHiddenLogin(userId: number) {
  const isDevUrl = window.location.href.includes("gesundheitsprogramm-apiservice-develop.azurewebsites.net") || window.location.href.includes("localhost");
  const isTestUrl = window.location.href.includes("gesundheitsprogramm-apiservice-test.azurewebsites.net");

  if (isDevUrl) {
    window.location.href = `https://localhost:44397/Home/hiddenlogin/${userId}`;
  } else if (isTestUrl) {
    window.location.href = `https://betriebskonzept-apiservice-test.azurewebsites.net/Home/hiddenlogin/${userId}`;
  } else {
    if (window.location.href.includes("programmedesante.apiservice.ch")) {
      window.location.href = `https://conceptexploitation.apiservice.ch/Home/hiddenlogin/${userId}`;
    } else if (window.location.href.includes("programmasalute.apiservice.ch")) {
      window.location.href = `https://concettoaziendale.apiservice.ch/Home/hiddenlogin/${userId}`;
    } else {
      window.location.href = `https://betriebskonzept.apiservice.ch/Home/hiddenlogin/${userId}`;
    }
  }
}
