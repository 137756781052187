//import testData from './TestData.json';
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/survey.i18n";

import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SelbstdeklarationDto } from "../../generated/api/generated-api";
import { CompleteSelbstdeklaration, CreateNewSelbstdeklaration, GetSelbstdeklaration, UdpateSelbstdeklaration } from "../../services/backend/Selbstdeklarationen";
import Button from "../../components/reusable/button";
import { Spinner, Toast } from "react-bootstrap";
import styled from "styled-components";
import { AuthContext } from "../../authorization/AuthProvider";
import i18next, { t } from "i18next";
import Card from "../../components/reusable/card";
import AbschliessenModal from "./AbschliessenModal";
import { useBeforeunload } from "react-beforeunload";
import { useTranslation } from "react-i18next";

// import { JsonObject, Serializer } from "survey-core"

const NavigationContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const MyCard = styled(Card)`
  margin: 0px 0px 20px 0px;
`;

function Selbstdeklaration() {
  let navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selbstdeklaration, setSelbstdeklaration] = useState<SelbstdeklarationDto>({});
  const [surveyModel, setSurveyModel] = useState<Model>(new Model());
  const [surveyData, setSurveyData] = useState({});
  const [showSaved, setShowSaved] = useState(false);
  const [showValidationError, setShowValidationError] = useState(false);
  const [showAbschliessenModal, setShowAbschliessenModal] = useState(false);
  const isBeforeUnloadActive = useRef(true);
  const [disableEinreichenButton, setDisableEinreichenButton] = useState(false);

  const handleBeforeUnload = (event: Event) => {
    if (selbstdeklaration.status === 1 && isBeforeUnloadActive.current) {
      event.preventDefault();
    }
  };
  useBeforeunload(handleBeforeUnload);

  const { id } = useParams();

  let auth = useContext(AuthContext);
  const user = auth.user;
  const userLoading = auth.userLoading;

  useEffect(() => {
    if (userLoading) return;

    if (user === undefined) {
      navigate("/login");
      return;
    }

    setLoading(true);

    if (id === "createNew") {
      CreateNewSelbstdeklaration(user.id, user.token)
        .then((selbstdeklarationId) => {
          navigate(`/selbstdeklaration/${selbstdeklarationId}`);
        })
        .finally(() => {
          setLoading(false);
        });
    }

    if (id !== "0" && id !== "createNew") {
      GetSelbstdeklaration(parseInt(id!), user.token)
        .then((selbstdeklaration) => {
          setSelbstdeklaration(selbstdeklaration!);
          setSurveyModel(createSurveyModel(selbstdeklaration!));
          setSurveyData(JSON.parse(selbstdeklaration!.selbstdeklarationJSON!));
          setDisableEinreichenButton(getDisableEinreichenbutton(selbstdeklaration!));
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [userLoading, navigate]);

  function getDisableEinreichenbutton(selbstdeklaration: SelbstdeklarationDto): boolean {
    console.log("selbstdeklaration status " + selbstdeklaration!.status);
    if (selbstdeklaration!.status !== 1) return true;
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const einreichenAb = new Date(selbstdeklaration.einreichenAb!);
    const einreichenBis = new Date(selbstdeklaration.einreichenBis!);

    return today < einreichenAb || today > einreichenBis;
  }

  useEffect(() => {
    surveyModel.locale = i18next.language.toLowerCase();
  }, [i18next.language]);

  function onSave(): void {
    setLoading(true);
    var data = surveyModel.getAllValues();
    console.log(data);
    UdpateSelbstdeklaration(selbstdeklaration.selbstdeklarationId!, JSON.stringify(data), user?.token!)
      .then(() => {
        console.log("saved");
      })
      .finally(() => {
        setLoading(false);
        setShowSaved(true);
      });
  }

  function onNextPage(): void {
    surveyModel.nextPage();
  }

  function onPrevPage(): void {
    surveyModel.prevPage();
  }

  function onAbschliessenButton(): void {
    if (!surveyModel.validate()) {
      setShowValidationError(true);
      return;
    }

    setShowAbschliessenModal(true);
  }

  function onAbschliessen(): void {
    setLoading(true);
    CompleteSelbstdeklaration(selbstdeklaration.selbstdeklarationId!, JSON.stringify(surveyModel.getAllValues()), user?.token!)
      .then(() => {
        isBeforeUnloadActive.current = false;
        window.location.href = `/selbstdeklaration/${selbstdeklaration.selbstdeklarationId!}`;
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function createSurveyModel(selbstdeklaration: SelbstdeklarationDto): Model {
    var model = new Model(selbstdeklaration.fragekatalogJSON);
    model.ignoreValidation = true;
    //model.showTOC = true;
    model.showNavigationButtons = false;
    model.mode = getSurveyMode(selbstdeklaration);
    model.locale = i18next.language.toLowerCase();

    return model;
  }

  function getSurveyMode(selbstdeklaration: SelbstdeklarationDto): string {
    if (user!.isAdmin && user!.id !== selbstdeklaration.imkerId) return "display";
    if (selbstdeklaration.status !== 1) return "display";
    
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const gueltigBis = new Date(selbstdeklaration.gueltigBis!);
    if (today > gueltigBis) return "display";
    
    return "edit";
  }

  return (
    <>
      {selbstdeklaration.status === 2 && <MyCard>{t("meineGesundheitsprogramme.selbstdeklaration_hinweistext_eingereicht")}</MyCard>}
      {selbstdeklaration.status === 3 && <MyCard>{t("meineGesundheitsprogramme.selbstdeklaration_hinweistext_angenommen")}</MyCard>}
      {selbstdeklaration.status === 4 && (
        <>
          <MyCard>
            {t("meineGesundheitsprogramme.selbstdeklaration_hinweistext_abgelehnt")}
            <i>{selbstdeklaration.statusBemerkung}</i>
          </MyCard>
        </>
      )}
      <Survey model={surveyModel} data={surveyData} />
      <NavigationContainer>
        <>
          <Button onClick={onPrevPage} disabled={loading}>
            {t("meineGesundheitsprogramme.selbstdeklaration_zurueck")}
          </Button>
          <Button onClick={onNextPage} disabled={loading}>
            {t("meineGesundheitsprogramme.selbstdeklaration_weiter")}
          </Button>
          <Button onClick={onSave} disabled={loading || selbstdeklaration.status !== 1}>
            {t("meineGesundheitsprogramme.selbstdeklaration_speichern")}
          </Button>
          <Button onClick={onAbschliessenButton} disabled={loading || disableEinreichenButton}>
            {t("meineGesundheitsprogramme.selbstdeklaration_absenden")}
          </Button>
          <p>
            {t("meineGesundheitsprogramme.selbstdeklaration_hinweis_einreichen", {
              einreichenVon: new Date(selbstdeklaration.einreichenAb!).toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" }),
              einreichenBis: new Date(selbstdeklaration.einreichenBis!).toLocaleDateString("de", { year: "numeric", month: "2-digit", day: "2-digit" }),
            })}
          </p>
        </>
        {loading && <Spinner as="span" animation="border" role="status" aria-hidden="true" />}
        <AbschliessenModal showModal={showAbschliessenModal} onComplete={onAbschliessen} onHide={() => setShowAbschliessenModal(false)}></AbschliessenModal>
        <Toast onClose={() => setShowSaved(false)} show={showSaved} delay={5000} autohide>
          <Toast.Body>{t("meineGesundheitsprogramme.selbstdeklaration_gespeichert")}</Toast.Body>
        </Toast>
        <Toast onClose={() => setShowValidationError(false)} show={showValidationError} delay={5000} autohide>
          <Toast.Body>
            <span style={{ color: "red" }}>{t("meineGesundheitsprogramme.selbstdeklaration_invalid")}</span>
          </Toast.Body>
        </Toast>
      </NavigationContainer>
    </>
  );
}

export default Selbstdeklaration;
