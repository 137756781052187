import { CircleFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { AmpelContainer } from "./Ampel";
import Button from "../../components/reusable/button";
import { useNavigate } from "react-router-dom";


function StatusAbgelehnt() {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();

  const teilnahmeErneuern = () => {
    navigate("/neuanmeldung?erneuern");
  }

  return (
    <>
      <AmpelContainer>
        <div>
          <CircleFill size={25} color="#ffffffab"></CircleFill>
        </div>
        <div>
          <CircleFill size={25} color="#ffffffab"></CircleFill>
        </div>
        <div>
          <CircleFill size={25} color="#ff3c3cab"></CircleFill>
        </div>
      </AmpelContainer>
      <p>{t("meineGesundheitsprogramme.teilnehmerStatusAbgelehnt")}</p>
      <Button onClick={teilnahmeErneuern}>{t("meineGesundheitsprogramme.teilnahmeErneuern")}</Button>
    </>
  );
}

export default StatusAbgelehnt;
