import { CircleFill } from "react-bootstrap-icons";
import { useTranslation } from "react-i18next";
import { AmpelContainer } from "./Ampel";
import styled from "styled-components";
import Button from "../../components/reusable/button";
import { useContext, useState } from "react";
import KuendigenModal from "./KuendigenModal";
import { Spinner } from "react-bootstrap";
import { GetMeineDatenBearbeitenLink } from "../../services/BetriebskonzeptLinks";
import { AuthContext } from "../../authorization/AuthProvider";

const Kuendigung = styled.div`
  width: 100%;
`;

export enum inZukunftGekuendigt {
  NichtGekuendigt,
  GekuendigtDurchBGD,
  GekuendigtDurchImker,
}

export interface StatusAufgenommenProps {
  inZukunftGekuendigt: inZukunftGekuendigt;
  teilnahmeGueltigBis: string;
  teilnahmeKuendigen: () => void;
}

function StatusAufgenommen(props: StatusAufgenommenProps) {
  const { t, i18n } = useTranslation();
  let auth = useContext(AuthContext);

  const [showKuendigenModal, setShowKuendigenModal] = useState(false);
  const [loading, setLoading] = useState(false);

  function onKuendigenButton(): void {
    setShowKuendigenModal(true);
  }

  function onKuendigen(): void {
    setLoading(true);
    props.teilnahmeKuendigen();
  }

  function GetStatusText() {
    if (props.inZukunftGekuendigt === inZukunftGekuendigt.NichtGekuendigt) {
      return t("meineGesundheitsprogramme.teilnehmerStatusAngenommen", { jahr: new Date().getFullYear() });
    }

    if (props.inZukunftGekuendigt === inZukunftGekuendigt.GekuendigtDurchImker) {
      return t("meineGesundheitsprogramme.teilnehmerStatusGekuendetDurchImker_zukunft", { datum: props.teilnahmeGueltigBis });
    }

    if (props.inZukunftGekuendigt === inZukunftGekuendigt.GekuendigtDurchBGD) {
      return t("meineGesundheitsprogramme.teilnehmerStatusGekuendetDurchBGD_zukunft", { datum: props.teilnahmeGueltigBis });
    }
  }

  const meineDatenBearbeiten = () => {
    window.location.replace(GetMeineDatenBearbeitenLink(auth.user!.id));
  };

  return (
    <>
      <AmpelContainer>
        <div>
          <CircleFill size={25} color="#18f308ab"></CircleFill>
        </div>
        <div>
          <CircleFill size={25} color="#ffffffab"></CircleFill>
        </div>
        <div>
          <CircleFill size={25} color="#ffffffab"></CircleFill>
        </div>
      </AmpelContainer>
      <p>{GetStatusText()}</p>
        <Button onClick={meineDatenBearbeiten}>{t("meineGesundheitsprogramme.meineDaten")}</Button>
        <Kuendigung>
          {loading ? <Spinner></Spinner> : <>{!props.inZukunftGekuendigt && <Button onClick={onKuendigenButton}>{t("meineGesundheitsprogramme.buttonKuendigen")}</Button>}</>}
          <KuendigenModal showModal={showKuendigenModal} onComplete={onKuendigen} onHide={() => setShowKuendigenModal(false)}></KuendigenModal>
        </Kuendigung>
    </>
  );
}

export default StatusAufgenommen;
